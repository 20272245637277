import { GiftCard, PostingAuction } from '@shobbak/react-services/dist/Entities';
import React, {useState} from 'react';
import { toast } from 'react-toastify';
import { ApplePay } from '../../helper/ApplePay';

import EventDispatcher from '../../helper/EventDispatcher';
import Translation from '../../helper/Translation';
import BackendCall, { HttpUnprocessableEntity } from '../BackendCall';
import Button from '../Button';
import Divider from '../Divider';
import OfferGiftCard from '../Offer/OfferGiftCard';
import PaymentButtons from '../PaymentButtons';
import { PaymentMethodName } from '../PaymentMethodsList';

type Props = {
  auction: PostingAuction;
  lineItems?: any;
  onSuccess?: (v) => void;
  onError?: (message: string) => void;
  onUpdate?: (newOffer: any) => void;
};

export type HandlePaymentParams = {
  card_id?: string;
  payment_handler: PaymentMethodName;
  payment_method: any;
  payment_data?: any;
  transaction_data?: any;
  card_cvv?: string;
  charge_wallet_too?: boolean;
};

const PaymentAction = ({
  auction,
  lineItems,
  onSuccess,
  onError,
  onUpdate,
}: Props) => {
  const [selectedGiftCard, setSelectedGiftCard] = React.useState<
    string | undefined
  >();
  const [giftCards, setGiftCards] = React.useState<GiftCard[]>();
  const [isProcessingPayment, setIsProcessingPayment] =
    useState<boolean>(false);

  React.useEffect(() => {
    BackendCall.i()
      .getUserGifts({filter: {usage: 'available'}})
      .then((gifts) => {
        setGiftCards(gifts);
      })
      .catch((error) => {
      });
  }, [auction]);

  React.useEffect(() => {
    const listener = EventDispatcher.on(
      'payment-callback',
      async ({ params }) => {
        setIsProcessingPayment(false);
        if (params?.payment_result === 'failed') {
          const message = Translation.t('texts.offer_update_failed');
          toast.error(message);
          return onError?.(message);
        }

				const newAuction = await BackendCall.i().createPostingAuction({
          user_shobbak_card_id: selectedGiftCard,
        });
				toast.success(
					Translation.t('texts.offer_updated')
				);
				onSuccess?.(newAuction);
      },
    );
    return () => {
      if (typeof listener === 'string') {
        EventDispatcher.remove('payment-callback', listener);
      }
    };
  }, []);

  const applyGiftCard = (giftCardId: string | undefined) => {
    BackendCall.i()
      .auctionActions(auction.id, 'set_gift_card', {
        user_shobbak_card_id: giftCardId,
      })
      .then((res) => {
        setSelectedGiftCard(giftCardId);
        onUpdate?.(res);
      })
      .catch((error) => toast.error(error));
  };

  const handlePayment = async (data: HandlePaymentParams) => {
    if (data.payment_handler === 'web_pay') {
			setIsProcessingPayment(true);
      if (auction.webPaymentUrl) {
          window.location.href = auction.webPaymentUrl
      }
      setIsProcessingPayment(false);
    } else {
      setIsProcessingPayment(true);
      try {
        const newAuction = await BackendCall.i().auctionActions(
          auction.id,
          'pay',
          {
            ...data,
            user_shobbak_card_id: selectedGiftCard,
          },
        );
        if (
          newAuction.payment3Ds.shouldRedirect &&
          data.payment_handler === 'card_pay'
        ) {
          window.location.href = newAuction.payment3Ds.url
        } else {
          setIsProcessingPayment(false);
          if (data.payment_handler === 'apple_pay') {
            ApplePay.complete(ApplePay.SUCCESS);
          }
          //update auction
          toast.success(
            Translation.t('texts.auction_updated')
          );
          onSuccess?.(newAuction);
        }
      } catch (error) {
        setIsProcessingPayment(false);
        if (data.payment_handler === 'apple_pay') {
          ApplePay.complete(ApplePay.FAILURE);
        }
        let message = Translation.t('texts.auction_update_failed');
        if (error instanceof HttpUnprocessableEntity) {
          message = error.messageStr();
        }
        toast.error(message);
        onError?.(message);
      } finally {
        setIsProcessingPayment(false);
      }
    }
  };
  const payByGiftCard = () => {
    setIsProcessingPayment(true);
    BackendCall.i()
      .auctionActions(auction.id, 'pay', {
        user_shobbak_card_id: selectedGiftCard,
      })
      .then(async (newAuction) => {
        setIsProcessingPayment(false);
        onSuccess?.(newAuction);
      })
      .catch(async (error) => {
        setIsProcessingPayment(false);

        let message = Translation.t('texts.offer_update_failed');
        if (error instanceof HttpUnprocessableEntity) {
          message = error.messageStr();
        }
        toast.error(message)
        onError?.(message);
      })
      .finally(() => {
        setIsProcessingPayment(false);
      });
  };

  const handleError = (message) => {
    setIsProcessingPayment(false);
    toast.error(message);
    onError?.(message);
  };

  const {
    cardPayAllowed,
    applePayAllowed,
    webPayAllowed,
    walletPayAllowed,
    canPayByWallet,
  } = auction;
  return (
    <div className="flex-1">
      {giftCards && giftCards?.length > 0 && (
        <>
          <div className="p-4">
            <p className="font-bold mb-2">
              {Translation.t('texts.available_gifts')}
            </p>
            <div className="flex-1">
              <div
                className="flex-1 flex overflow-x-auto"
              >
                {giftCards.map((gift) => (
                  <OfferGiftCard
                    selected={String(selectedGiftCard) === String(gift.id)}
                    gift={gift}
                    key={gift.id}
                    onPress={() => {
                      applyGiftCard(
                        String(selectedGiftCard) === String(gift.id)
                          ? undefined
                          : gift.id,
                      );
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
          <Divider height={8} />
        </>
      )}

      {!auction?.coveredByGift && (
        <PaymentButtons
          amount={String(auction?.processingFee.value)}
          offerAmount={auction?.processingFee.value}
          deliveryFee={0}
          currency={auction?.currency}
          paymentOptions={auction ? auction.paymentOptions : []}
          onSuccess={handlePayment}
          onFailure={handleError}
          isProcessingPayment={isProcessingPayment}
          onStartPayment={() => setIsProcessingPayment(true)}
          readyToPay
          walletPayAllowed={walletPayAllowed}
          cardPayAllowed={cardPayAllowed}
          applePayAllowed={ApplePay.isAvailable() && applePayAllowed}
          canPayByWallet={canPayByWallet}
					webPayAllowed={!cardPayAllowed && !(ApplePay.isAvailable() && applePayAllowed) && webPayAllowed}
          lineItems={lineItems}
          onPaymentMethodUpdate={()=> {}}
          withWalletLineItems={[]}
          messages={[]}
        />
      )}
      {auction?.coveredByGift && (
        <Button
          loading={isProcessingPayment}
          label={Translation.t('buttons.continue')}
          type="solid"
          containerStyle={'mt-4 w-full'}
          onPress={payByGiftCard}
        />
      )}
    </div>
  );
};

export default PaymentAction;
