import React, { lazy, Suspense, useEffect, useRef, useState } from 'react'
import BackendCall from '../src/Components/BackendCall'
import Loader from '../src/Components/icon/Loader'
import Translation from "../src/helper/Translation";
import { User } from "@shobbak/react-services/dist/Entities";
import { Theme } from '@shobbak/react-services';
import ChangeUserProfile from "../src/Components/EditProfile/ChangeUserProfile";
import DefaultButton, { AlertButton, ButtonElement, DeleteButton } from "../src/Components/EditProfile/DefaultButton";
import EditPassword from "../src/Components/EditProfile/EditPassword";
import PersonalInformation from "../src/Components/EditProfile/PersonalInformation";
import VerificationMethod from "../src/Components/EditProfile/VerificationMethod";
import isEmpty from 'lodash/isEmpty'
import { toast } from "react-toastify";
import LoginSvgIcon from "../src/Assets/svg/login/LoginSvgIcon";
import EventDispatcher from "../src/helper/EventDispatcher";
import ReCAPTCHA from "react-google-recaptcha";
import ArrowRightLine from '../src/Components/icon/ArrowRightLine';
import ArrowLeftLine from '../src/Components/icon/ArrowLeftLine';
import AuthPasswordInput from "../src/Components/Auth/AuthPasswordInput";
import DefaultInput from "../src/Components/EditProfile/DefaultInput";


// @ts-ignore
const ProfileLayout = lazy(() => import("../src/Components/ProfileLayout"))
// @ts-ignore
const ProfileCard = lazy(() => import("../src/Components/profile/ProfileCard"))
const { Colors } = Theme;


export type userProfileFormType = {
  avatar: File | null;
  email: string;
  full_name: string;
  password: string;
  currentPassword: string;
  emailConfirmed: boolean;
  birthdate: string;
  gender: string;
  mobile: {
    id: number;
    number: number;
    verified: boolean;
    countryId: number;
    countryCode: string;
    nextNotificationTime: string | null
  } | any

}


const initUserProfileForm = {
  avatar: null,
  mobile: {},
  email: '',
  full_name: '',
  password: '',
  currentPassword: '',
  emailConfirmed: true,
}

export type VerificationField = {
  key: string;
  name: string;
  placeholder: string;
  type: string;
};

export type ProfileVerificationMethod = {
  afterInput: string;
  description: string;
  fields: VerificationField[];
  heading: string;
  icon: string;
  id: string;
  key: string;
  metadata: {
    canRequest: boolean;
    status?: string;
    canSendAgain?: boolean;
    expiresAt?: string;
    id?: number;
    nextRequestAt?: string;
  };
  name: string;
  pageTitle: string;
  relationships: any;
  type: string;
};


export default function EditProfile({ token, locale, enabledCountries, recaptchaEnabled = true }) {
  const [isLoading, setIsLoading] = useState<Boolean>(true)
  const [user, setUser] = useState<User | any>({})
  const [userProfileForm, setUserProfileForm] = useState<userProfileFormType>(initUserProfileForm)
  const [verificationMethods, setVerificationMethods] = useState<ProfileVerificationMethod[]>();
  const [errorBag, setErrorBag] = useState([])
  const [deleteModeEnable, setDeleteModeEnable] = useState<boolean>(false)
  const [accountSettingModeVisible, setAccountSettingsModeVisibility] = useState<boolean>(false)
  const [licensesChecked, setLicensesChecked] = useState<boolean>(false)
  const recaptchaRef = useRef(null)
  const [canSubmitForm, setCanSubmitForm] = useState<boolean>(false)
  const [passwordValid, setPasswordValid] = useState<boolean>(false)
  const [passwordConfirmationValid, setPasswordConfirmationValid] = useState<boolean>(false)


  const validateAndSubmit = async () => {
    const data = userProfileForm
    let file = {}
    if (data.avatar instanceof File) {
      file = { name: 'avatar', file: data.avatar }
    }

    if (!data.password || !data.currentPassword) {
      delete data.password
      delete data.currentPassword
    }
    delete data.avatar
    if (recaptchaEnabled) {
      await recaptchaRef.current.reset()
      let recaptcha_token = await recaptchaRef.current.executeAsync()
      data['recaptcha_token'] = recaptcha_token
    }
    BackendCall.i({ token, locale })
      .usersUpdate(data, file)
      .then(res => {
        toast.success(Translation.t('texts.profile_update_successfully'))
        setUser(res)
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      }).catch(({ errors }) => {
        setErrorBag(errors)
      })
  }


  React.useEffect(() => {
    EventDispatcher.on('confirmed', (payload) => {
      BackendCall.i({ token, locale })
        .deleteUser({ reason: payload.detail?.reason })
        .then((res) => {
          window.location.reload()
        }).catch(({ errors }) => {
          EventDispatcher.dispatch('close-warning-modal')
          setErrorBag(errors)
        })
    })

    EventDispatcher.on('confirm-password-request', (payload) => {
      BackendCall.i({ token, locale })
        .confirmPassword(payload.detail)
        .then(res => {
          EventDispatcher.dispatch('close-confirm-password-modal')
          launchWarningModal()
        })
        .catch(err => {
          EventDispatcher.dispatch('confirm-password-error', { error: err?.errors?.errors?.password })
        })
    })

    EventDispatcher.on('discard', (payload) => {
      EventDispatcher.dispatch('close-warning-modal')
    })
  }, [])

  React.useEffect(() => {
    getVerificationMethods();
  }, []);

  const getVerificationMethods = () => {
    BackendCall.i({ locale, token })
      .getVerificationMethods()
      .then((methods) => {
        setVerificationMethods(methods);
      })
      .catch((error) => { });
  };

  const updateMethods = (method) => {
    let newVerificationMethods = verificationMethods
    newVerificationMethods = newVerificationMethods.map((item) => {
      return item.key == method.key ? method : item
    })

    setVerificationMethods(newVerificationMethods)

  }
  const updateUserProfileState = (objectValue = {}) => {
    setUserProfileForm((prev) => {
      return { ...prev, ...objectValue }
    })
  }

  const getFormDataFromUser = (userResponse) => {
    return { email: userResponse.email, gender: userResponse.gender || 'male', birthdate: userResponse.birthdate, emailConfirmed: userResponse.emailConfirmed, full_name: userResponse.fullName, password_confirmation: '', password: '', avatar: userResponse.avatarUrl, mobile: userResponse.mobile }
  }

  useEffect(() => {
    BackendCall.i({ locale, token })
      .currentUser()
      .then(userResponse => {
        setUser(userResponse)
        //@ts-ignore
        setUserProfileForm(getFormDataFromUser(userResponse))
        setIsLoading(false)
      })
  }, [])

  const renderEditProfileMode = () => {
    return (<>
      <ProfileCard title={Translation.t('profile_sidebar.edit_profile')}>
        {!deleteModeEnable && (
          <>
            <div className="flex flex-col divide-y gap-4">
              {isLoading && <Loader />}
              {!isLoading && (
                <>
                  <ChangeUserProfile profileUrl={userProfileForm.avatar} onProfileChange={(file) => { updateUserProfileState({ avatar: file }) }} />
                  <PersonalInformation email={userProfileForm.email}
                    enabledCountries={enabledCountries}
                    emailVerified={userProfileForm.emailConfirmed}
                    fullName={userProfileForm.full_name}
                    mobile={userProfileForm.mobile}
                    token={token}
                    locale={locale}
                    recaptchaEnabled={recaptchaEnabled}
                    onInputChange={(obj) => { updateUserProfileState(obj) }} />

                  <div className={`flex flex-col gap-2`}>
                    <h2 className={`text-sm leading-5 font-medium text-coolGray-700 mt-4`}>{Translation.t('texts.change_password')}</h2>
                    <div className={`flex md:flex-row flex-col  gap-4 w-full`}>
                      <AuthPasswordInput withValidationInstructions={false}
                        label={Translation.t('inputs.current_password.label')}
                        passwordChangeHandler={(password) => updateUserProfileState({ currentPassword: password })}
                        isValid={(valid: boolean) => { setPasswordValid(valid) }} />

                      <AuthPasswordInput withValidationInstructions
                        label={Translation.t('inputs.new_password.label')}
                        passwordChangeHandler={(password) => updateUserProfileState({ password: password })}
                        isValid={(valid: boolean) => { setPasswordConfirmationValid(valid) }} />
                    </div>
                  </div>

                  <div className={`flex gap-2 w-full pt-4`}>
                    <DefaultInput name={`birthdate`}
                      label={Translation.t('dispute.labels.birth_date')}
                      type="date"
                      containerClass="w-full"
                      value={userProfileForm.birthdate}
                      onValueChange={(value) => { updateUserProfileState({ birthdate: value }) }} />


                    <Gender active={userProfileForm?.gender} genderChangeHandler={(gender) => { updateUserProfileState({ gender: gender }) }} />

                  </div>

                  {verificationMethods?.map((payload) => {
                    return (<VerificationMethod updateMethods={(method) => updateMethods(method)} locale={locale} token={token} methodPayload={payload} key={payload?.id} />);
                  })}


                </>
              )}

            </div>
            <div className={`flex md:justify-end sm:w-full md:w-auto`}>
              <DefaultButton containerClass={`mt-4 md:w-auto w-full flex justify-end`}
                label={Translation.t('buttons.save')}
                disabled={!passwordConfirmationValid && userProfileForm?.password?.length > 0}
                onButtonClick={validateAndSubmit} />
            </div>
          </>
        )}
      </ProfileCard>
      {/* <div className={`flex md:flex-row flex-col gap-4 md:items-center md:justify-between p-4  border bg-white   border-coolGray-100 rounded-md`}>
        <p className={`text-lg leading-6 font-semibold text-coolGray-700`}>{Translation.t('delete_account.screen.title')}</p>
        <AlertButton containerClass={`md:h-12 h-14`} label={Translation.t('delete_account.buttons.delete_account')} onButtonClick={() => { setDeleteModeEnable(true) }} />
      </div> */}
      {renderAccountSettingsHeader()}
    </>)
  }

  const renderDeleteAccountMode = () => {
    return (
      <ProfileCard title={``}>
        <div className={`flex flex-col items-center justify-center min-h-62`}>
          <div className={`md:max-w-lg flex flex-col gap-4`}>
            {/* icon */}
            <div className={`text-center flex items-center justify-center w-full`}>
              <LoginSvgIcon />
            </div>
            {/* title*/}
            <p className={`text-xl text-black-700 leading-7 text-center font-bold`}>{Translation.t('delete_account.sections.header.title')}</p>
            {/* dashed sections  */}
            <div className={`w-full text-center flex items-center justify-center border border-dashed  border-coolGray-200 bg-coolGray-50 rounded-md py-3 px-6`}>
              <p className={`text-sm leading-5 font-normal text-coolGray-500`}>
                {Translation.t('delete_account.sections.hint.start')} <span className={`font-semibold text-coolGray-700 mx-1`}  >{Translation.t('delete_account.sections.hint.days_limit')}</span>
                {Translation.t('delete_account.sections.hint.end')}
              </p>
            </div>
            {/* you should know section  */}
            <div className={`flex flex-col gap-4 mt-2`}>
              <p className={`text-start text-base leading-6 font-semibold text-black`}>{Translation.t('delete_account.sections.tips.title')}</p>
              <ul className={`flex flex-col gap-4`}>
                {Translation.t('delete_account.sections.tips.items').map((item, index) => {
                  return (
                    <li className={`flex items-start gap-2`} key={index}>
                      <div className={`flex items-center justify-center bg-coolGray-50 rounded-md w-8 h-8`}>
                        <i className={item?.icon} />
                      </div>
                      <div className={`flex flex-col  ${index != 2 ? 'pb-4 border-b border-coolGray-100' : ''}`}>
                        <p className={`text-sm leading-5 font-semibold text-coolGray-700`}>{item?.title}</p>
                        <p className={`text-sm leading-0 font-normal text-coolGray-500`}>{item?.sub_title}</p>
                      </div>
                    </li>
                  )
                })}

              </ul>

            </div>
            {/*  Restored Account  */}
            <div className={`flex flex-col gap-2 w-full`}>
              <p className={`text-base leading-6 font-semibold text-black`}>{Translation.t('delete_account.sections.bullets.title')}</p>
              <ul className={`list-disc text-coolGray-500 text-sm leading-5 font-normal px-4`}>
                {Translation.t('delete_account.sections.bullets.items').map((item, key) => <li key={key} >{item?.title}</li>)}
              </ul>
            </div>
            {/*  licenses readed   */}
            <div className={`flex items-center gap-3`}>
              <input id="licenses_readed" name="licenses_readed" type="checkbox" onClick={() => { setLicensesChecked(!licensesChecked) }} checked={licensesChecked} className="rounded-sm focus:ring-emerald-500  h-4 w-4 text-emerald-600 border-gray-300" />
              <label htmlFor={'licenses_readed'} className={`text-sm  leading-5 font-normal text-black`}>{Translation.t('delete_account.messages.accept_aggrement')}</label>
            </div>

            <div className={`flex flex-col w-full pt-4 gap-2`}>
              <ButtonElement label={Translation.t('buttons.cancel')} className={`${licensesChecked ? 'text-white bg-red-500 border-red-500' : 'text-coolGray-700 bg-coolGray-300 border-coolGray-300'}  md:w-full h-12 `} onButtonClick={() => {
                setDeleteModeEnable(false);
                setAccountSettingsModeVisibility(false);
                setLicensesChecked(false);
              }} />
              <button
                className={`${licensesChecked ? 'cursor-pointer' : ' disabled cursor-not-allowed'}  w-full text-coolGray-400 underline`}
                onClick={() => {
                  if (!licensesChecked) return;
                  launchConfirmPasswordModal()
                  // launchWarningModal()
                }}>
                {Translation.t('delete_account.buttons.delete_account')}
              </button>
            </div>
          </div>
        </div>
      </ProfileCard>
    );
  }


  const Gender = ({ active = 'male', genderChangeHandler }) => {
    const baseClass = "cursor-pointer whitespace-nowrap  border border-transparent rounded-md h-9 py-1.5 px-4 items-center justify-center text-sm font-normal leading-5"
    const activeClass = " text-emerald-500 border-emerald-500"
    const normalClass = " text-coolGray-500 border-coolGray-500"

    const getCurrentClass = (current) => {
      return baseClass.concat(active == current ? activeClass : normalClass)
    }

    return <div className="flex flex-col gap-1 w-full">
      <div className="flex flex-col gap-2">
        <span className="text-base leading-7 text-semibold text-coolGray-700"> {Translation.t("dispute.labels.gender")}</span>
        <div className="flex gap-2">
          <button className={getCurrentClass('female')} onClick={() => genderChangeHandler('female')}>{Translation.t('dispute.labels.female')}</button>
          <button className={getCurrentClass('male')} onClick={() => genderChangeHandler('male')}>{Translation.t('dispute.labels.male')}</button>
        </div>
      </div>
    </div>

  }

  const renderAccountSettingsHeader = () => {
    return (
      <ProfileCard title={``}>
        <button onClick={() => {
          setAccountSettingsModeVisibility(true)
          document.getElementsByTagName('header')[1]?.scrollIntoView()
        }} className={`flex justify-between items-center min-h-62 w-full`}>
          <p className={`text-lg leading-6 text-coolGray-700`}>{Translation.t('buttons.settings')}</p>
          {locale == "ar" ? <ArrowLeftLine /> : <ArrowRightLine />}
        </button>
      </ProfileCard>
    )
  }

  const renderAccountSettings = () => {
    return (
      <ProfileCard title={Translation.t('buttons.settings')}>
        <div className="mb-4">
          <hr />
        </div>
        <button onClick={() => setDeleteModeEnable(true)} className={`flex justify-between items-center min-h-62 mb-4 w-full`}>
          <p className={`text-lg leading-6 text-coolGray-700`}>{Translation.t('delete_account.screen.title')}</p>
          {locale == "ar" ? <ArrowLeftLine /> : <ArrowRightLine />}
        </button>
        <button onClick={() => setAccountSettingsModeVisibility(false)} className={`text-md leading-6 text-coolGray-500`}>{Translation.t('buttons.back')}</button>
      </ProfileCard>
    )
  }

  function launchConfirmPasswordModal() {
    EventDispatcher.dispatch('confirm-password', {
      title: Translation.t('delete_account.modals.confirm_password.title'),
      paragraph: Translation.t('delete_account.modals.confirm_password.description'),
      display_action: Translation.t('delete_account.buttons.confirm'),
      inputs: [
        {
          id: 1,
          label: Translation.t('delete_account.inputs.password.label'),
          type: 'password',
          name: 'password'
        }
      ]
    })
  }

  function launchWarningModal() {
    EventDispatcher.dispatch('open-warning-modal',
      {
        title: Translation.t('delete_account.modals.confirm_delete.title'),
        paragraph: Translation.t('delete_account.modals.confirm_delete.description'),
        display_action: Translation.t('delete_account.buttons.delete_account'),
        inputs: [
          {
            id: 1,
            label: Translation.t('delete_account.inputs.reason.label'),
            type: 'textarea',
            name: 'reason',
            isRequired: true
          }
        ]
      })
  }

  return (
    <Suspense fallback={Loader()}>
      {/*@ts-ignore*/}
      <ProfileLayout errorBag={errorBag} withOutTitleInErrorBag={true} >

        {recaptchaEnabled && <ReCAPTCHA
          size="invisible"
          ref={recaptchaRef}
          sitekey={window.env?.RECAPTCHA_KEY}
        />
        }
        {deleteModeEnable ? renderDeleteAccountMode() : accountSettingModeVisible ? renderAccountSettings() : renderEditProfileMode()}
      </ProfileLayout>
    </Suspense>
  )
}


