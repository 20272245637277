import React, { lazy, Suspense, useEffect, useState } from "react";

const Breadcrumb = lazy(() => import("../src/Components/Posting/Breadcrumb"))
const OfferStateImage = lazy(() => import("../src/Components/Offer/OfferStateImage"))
const OfferPostingInfo = lazy(() => import("../src/Components/Offer/OfferPosingInfo"))
const PostingInfo = lazy(() => import("../src/Components/Offer/PostingInfo"))
const RejectionReasonModal = lazy(() => import("../src/Components/Offer/RejectionReasonModal"))
const PricesBreakDown = lazy(() => import("../src/Components/Offer/PricesBreakDown"))
const Button = lazy(() => import("../src/Components/Button"))
const AlertModal = lazy(() => import("../src/Components/AlertModal"))
const DisputeModal = lazy(() => import("../src/Components/Offer/DisputeModal"))
const RejectReason = lazy(() => import("../src/Components/Offer/RejectReason"))
const Divider = lazy(() => import("../src/Components/Divider"))
const SellerDeliveryOption = lazy(() => import("../src/Components/Offer/Actions/SellerDeliveryOption"))
const DropOffModal = lazy(() => import("../src/Components/ShobbakHub/DropOffModal"))
const DeliveryOptions = lazy(() => import("../src/Components/Offer/Actions/DeliveryOptions"))
const ShipAction = lazy(() => import("../src/Components/Offer/Actions/ShipAction"))
const ShipmentInformationModal = lazy(() => import("../src/Components/Offer/ShipmentInformationModal"))
const ShipmentInfo = lazy(() => import("../src/Components/Offer/ShipmentInfo"))
const BriskItemTracking = lazy(() => import("../src/Components/Offer/BriskItemTracking"))
const PaymentMethodsDetails = lazy(() => import("./PaymentMethodDetails"))
import { renderOfferMessages } from '../src/Components/Offer/renderOfferMessages'

import { sidebar } from "../src/helper/Sidebar";
import BackendCall, { HttpUnprocessableEntity } from "../src/Components/BackendCall";
import intersection from 'lodash/intersection';
import without from 'lodash/without';
import isEmpty from 'lodash/isEmpty';
import { useRemoteItem } from "../src/Hooks/useRemoteItem";
import { Posting, PostingOffer, PostingOfferAction } from "@shobbak/react-services/dist/Entities";
import Translation from '../src/helper/Translation'
import { toast } from "react-toastify";
import AppContext, { Env } from "../src/Services/AppContext";
import { Provider } from "react-redux";
import { store } from '../src/redux/store'
import Loader from "../src/Components/icon/Loader";
import PaymentAction from "../src/Components/Offer/Actions/PaymentAction";
import EventDispatcher from "../src/helper/EventDispatcher";
import OfferReceivedIcon from "../src/Components/icon/OfferReceivedIcon";
import OfferCancelledIcon from "../src/Components/icon/OfferCancelledIcon";
import ShobbakBoxIcon from "../src/Components/icon/ShobbakBoxIcon";
import DeliveryCodeConfirmationModal from "../src/Components/DeliveryCodeConfirmationModal";
import { Community } from "../entities";
import ShobbakBoxIconError from "../src/Components/icon/ShobbakBoxIconError";
import RateUserModal from "../src/Components/RateUserModal";

const BREADCRUMB_ITEM = { id: 100, title: "Offer Details" };

interface OfferProps {
  token: string;
  locale: string;
  offerId: string | number;
  authUser: any;
  env: Env;
  posting?: Posting;
  triggerPaymentCompletedEvent: boolean;
  community?: Community;
}

function Offer({ token, locale, offerId, authUser, env, posting, triggerPaymentCompletedEvent = false, community }: OfferProps) {
  BackendCall.i({ token, locale });

  const dataLoader = async () => {
    return await BackendCall.i().getPostingOffers(offerId);
  };
  const [remoteOffer, fetchOffer] = useRemoteItem<PostingOffer>({
    dataLoader,
  });
  const [offer, setOffer] = React.useState(remoteOffer.item);
  const [disputeModalVisible, setDisputeModalVisible] =
    React.useState<boolean>(false)

  const [logisticInfoModalVisible, setLogisticInfoModalVisible] = React.useState(false)
  const [actionLoading, setActionLoading] = React.useState<boolean>(false);
  const [currentUserRole, setCurrentUserRole] = React.useState<"unknown" | "buyer" | "seller">();
  const [onboardingVisible, setOnboardingVisible] =
    React.useState<boolean>(false);
  const [onboardingDismissed, setOnboardingDismissed] = React.useState(false);
  const [alertModalVisible, setAlertModalVisible] =
    React.useState<boolean>(false);
  const [alertAction, setAlertAction] = React.useState<PostingOfferAction>();
  const [rejectReasonModalVisible, setRejectReasonModalVisible] =
    React.useState(false);
  const [dropOffModalVisible, setDropOffModalVisible] =
    React.useState(false);
  const [rateUserModalVisible, setRateUserModalVisible] = React.useState(false)
  const [deliveryConfirmationCodeVisible, setDeliveryConfirmationCodeVisible] = useState(false);
  const [logisticProvider, setLogisticProvider] = React.useState<any>();
  const [deliveryOptionIsAvailable, setDeliveryOptionAvailability] = React.useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<'wallet' | 'card_pay' | null>(null)

  const loadData = (newOffer?: PostingOffer) => {
    if (newOffer) {
      setOffer(newOffer);
    } else {
      fetchOffer();
    }
  };

  React.useEffect(() => {
    offerId && loadData();
  }, [offerId]);


  useEffect(() => {
    window.addEventListener("posting_offer_updated", () => {
      loadData()
    });
    return () => {
      window.removeEventListener("posting_offer_updated", () => {
      });
    };
  }, []);

  React.useEffect(() => {
    setOffer(remoteOffer.item);
    if (remoteOffer.item?.id && triggerPaymentCompletedEvent) {
      window.trackActivity({ event: 'purchase', rawPayload: { ...remoteOffer.item, ...remoteOffer.item?.posting, offer_created_at: remoteOffer.item.createdAt }, shouldRedirect: false })
    }
    if (remoteOffer.item?.id && remoteOffer.item?.role) {
      // check if the current user is a buyer or seller
      setCurrentUserRole(remoteOffer.item?.role);
      // Set onboarding in case seller just opened the offer for first time (i.e. offer status is offered)
      remoteOffer.item?.status === "offered" &&
        remoteOffer.item?.role === "seller" &&
        !onboardingDismissed &&
        setOnboardingVisible(true);
    }
  }, [remoteOffer.item]);

  React.useEffect(() => {
    if(offer) {
      setRateUserModalVisible(offer.flags.canRate)
    }
  }, [offer])

  /**
   * Renders
   */
  const { allowedActions = [] } = offer || {};
  const bottomActions: PostingOfferAction[] = intersection(allowedActions, [
    'block',
    'cancel',
    'start_dispute',
  ]);

  const mainActions: PostingOfferAction[] = intersection(allowedActions, [
    'pay',
    'send_offer',
    'ship',
  ]);


  const normalActions: PostingOfferAction[] = without(
    allowedActions,
    ...bottomActions,
    ...mainActions,
  );


  const renderCourierShippingInfo = () => {
    return (
      offer?.flags?.showShippingInfo && (
        <div>
          <Divider height={8} />
          <ShipmentInfo offer={offer} />
        </div>
      )
    );
  };

  const actionHandler = async (action: PostingOfferAction, params?: any) => {
    setActionLoading(true);
    try {
      const newOffer = await BackendCall.i().actionPostingOffers(
        `${offerId}`,
        action,
        params,
      );
      setActionLoading(false);
      loadData(newOffer);
      if (action == 'accept') {
        window.trackActivity({ event: 'post_offer_accepted', rawPayload: { ...offer, ...posting, offer_created_at: offer.createdAt }, shouldRedirect: false })
      }
    } catch (error) {
      let message = Translation.t('texts.offer_update_failed');
      if (error instanceof HttpUnprocessableEntity) {
        message = error.messageStr();
      }
      setActionLoading(false);
      toast.error(message)
    }
  }

  const confirmAction = (action) => {
    setAlertAction(action);
    setAlertModalVisible(true);
  };

  const renderOfferHeaderImage = () => {
    return (
      offer && (
        <OfferStateImage
          showPrice={offer?.flags.showTopPrice}
          showDefault={!offerId}
          offer={offer}
        />
      )
    );
  };

  const renderOfferSelectedDelivery = () => {
    return (
      offer?.flags?.showSelectedDelivery && (
        <div>
          {renderOfferMessages(offer?.messages, 'above_delivery')}
          <SellerDeliveryOption
            offer={offer}
            role={offer?.role}
            deliveryOption={offer?.deliveryOptionDetail}
            hubOrder={offer.hubOrder}
            offerAddress={offer.addressInfo}
            offer_id={offer.id}
            flags={offer.flags}
            sellerAddress={offer.sellerAddressInfo}
            currentCountry={authUser.country}
            googleMapsApiKey={env.FRONTEND_GOOGLE_MAP_KEY}
            onUpdate={loadData}
          />
          {renderOfferMessages(offer?.messages, 'under_delivery')}
        </div>
      )
    );
  };

  const renderModals = () => {
    return (
      <>
        <DisputeModal
          onUpdate={loadData}
          isVisible={disputeModalVisible}
          onClose={() => setDisputeModalVisible(false)}
          offerId={offerId as string}
          phoneRequired={!offer?.flags.hasVerifiedMobileNumber}
          onDispute={(dispute_type, customDisputeType, userComment) => {
            actionHandler('start_dispute', {
              dispute_type,
              custom_dispute_type: customDisputeType,
              comments: userComment,
            });
          }}
        />
        <AlertModal
          onClose={() => setAlertModalVisible(false)}
          iconName={
            alertAction === 'block'
              ? 'ri-alert-fill'
              : alertAction === 'receive'
                ? 'ri-checkbox-multiple-fill'
                : 'ri-close-circle-fill'
          }
          iconColor={
            alertAction === 'receive'
              ? 'text-emerald-500'
              : 'text-red-500'
          }
          visible={alertModalVisible}
          headline={
            alertAction === 'receive'
              ? Translation.t('texts.item_delivered')
              : Translation.t(`buttons.offer_actions.${alertAction}`)
          }
          body={
            alertAction === 'receive'
              ? Translation.t('texts.money_will_be_deposited')
              : Translation.t('texts.offer_are_you_sure', {
                actionName: Translation.t(`buttons.offer_actions.${alertAction}`),
              })
          }
          rowButtons={[
            {
              type: alertAction === 'receive' ? 'outline' : 'solid',
              label:
                alertAction === 'receive'
                  ? Translation.t('buttons.discard')
                  : Translation.t(`buttons.keep_it`),
              onPress: () => {
                setAlertModalVisible(false);
              },
              color:
                alertAction === 'receive'
                  ? 'coolGray-400'
                  : 'emerald-500',
            },
            {
              type: alertAction === 'receive' ? 'solid' : 'clear',
              label: Translation.t(`buttons.offer_actions.${alertAction}`),
              onPress: () => {
                alertAction && actionHandler(alertAction);
                setAlertModalVisible(false);
              },
              color:
                alertAction === 'receive'
                  ? 'emerald-500'
                  : 'coolGray-400',
            },
          ]}
        />

        <DeliveryCodeConfirmationModal
          offer={offer}
          isVisible={deliveryConfirmationCodeVisible}
          onClose={(refresh: boolean) => {
            setDeliveryConfirmationCodeVisible(false)
            if (refresh) window.location.reload()
          }}
        />

        {/* <OfferStepsModal
         visible={stepsModalVisible}
         onClose={() => setStepsModalVisible(false)}
        /> */}

        <ShipmentInformationModal
          onSuccess={loadData}
          onClose={() => setLogisticInfoModalVisible(false)}
          visible={logisticInfoModalVisible}
          logisticProvider={logisticProvider}
          offer={offer}
        />

        { offer && <RateUserModal
          offer={offer}
          visible={rateUserModalVisible}
          onClose={() => setRateUserModalVisible(false)}
        /> }
      </>
    );
  };

  const renderOfferAction = (action: PostingOfferAction) => {
    switch (action) {
      // TODO handle send_offer ui/ux
      case 'send_offer':
        return null;
      case 'pay':
        return offer ? (
          <PaymentAction
            isDisabled={!deliveryOptionIsAvailable}
            offer={offer}
            onSuccess={(newOffer) => loadData(newOffer)}
            onUpdate={(newOffer) => loadData(newOffer)}
            selectedGiftCard={offer?.userShobbakCard?.id}
            selectedGiftCardObj={offer?.userShobbakCard}
            onPaymentMethodUpdate={(method) => setSelectedPaymentMethod(method)}
          />
        ) : null;
      case 'ship':
        return offer ? (
          <ShipAction
            offer={offer}
            onSuccess={(newOffer) => loadData(newOffer)}
            onSelectProvider={setLogisticProvider}
          />
        ) : null;
    }
  };

  function renderDeliveryCode() {
    if (!offer?.statusInfo?.isCode) return;

    return (
      <div className="flex flex-col items-center bg-white rounded-lg p-6 mb-4">
        <h3 className="text-3xl leading-9 font-bold text-emerald-500 mb-4">{offer?.statusInfo?.title}</h3>
        <h4 className="text-lg leading-6 font-normal text-coolGray-700 mb-2">{offer?.statusInfo?.subtitle}</h4>
        <h5 className="text-base leading-5 font-normal text-coolGray-400">{offer?.statusInfo?.description}</h5>
      </div>
    )
  }


  function renderExtraStatusMessage() {
    if (!offer?.extraStatusMessage) return;

    return (
      <div className="p-4 bg-white">
        <div className={`p-4 rounded-lg ${offer?.extraStatusMessage.backgroundStyle}`}>
          <div className="flex">
            <div className="me-2">
              <i className={`${offer?.extraStatusMessage.icon}`} />
            </div>
            <div>
              <p className={`text-xs leading-4 font-semibold ${offer?.extraStatusMessage.titleStyle}`}>{offer?.extraStatusMessage.title}</p>
              <p className={`text-xs leading-4 font-normal ${offer?.extraStatusMessage.descriptionStyle}`}>{offer?.extraStatusMessage.description}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function renderOfferStatusInfo() {
    if (!offer) return;
    if (!offer.showStatusInfoOnWebsite) return;
    return (
      <div className="p-8 bg-white flex flex-col items-center rounded">
        <div>
          {renderOfferStatusIcon()}
        </div>
        <div className="my-4 w-full flex flex-col items-center">
          <h2 className="text-coolGray-800 text-lg leading-7 font-semibold mb-2" style={{ color: offer?.statusInfo?.foregroundColor }}>{offer.statusInfo.title}</h2>
          <div className="flex flex-col items-center text-center gap-2">
            {offer?.statusInfo?.subtitle && ( <p className="text-coolGray-600 text-base leading-7 font-bold">{offer?.statusInfo?.subtitle}</p>)}
            <p className="text-coolGray-600 text-base leading-6 font-normal" dangerouslySetInnerHTML={{__html: offer.statusInfo.description}}></p>
          </div>
        </div>
        {renderOfferStatusMessage()}
        {renderOfferRejectionReason()}
      </div>
    )
  }

  function renderOfferStatusMessage() {
    if (!offer || !offer.statusMessage) return;
    return (
      <div className="md:p-4 p-2 rounded-lg w-full" style={{ backgroundColor: offer.statusMessage.backgroundColor }}>
        <div className="flex gap-1 w-full">
          <i className={`ri-${offer.statusMessage.icon} ri-2x`} style={{ color: offer.statusMessage.foregroundColor }} />
          <div className="flex flex-col">
              <p className={`text-base leading-5 font-semibold`} style={{ color: offer.statusMessage.foregroundColor }}>{offer.statusMessage.title}</p>
              <p className={`text-sm leading-5 font-normal`} style={{ color: offer.statusMessage.foregroundColor }}>{offer.statusMessage.description}</p>
          </div>
        </div>
        { offer.statusMessage.link && ( <a href={offer.statusMessage.link} target="_blank" className="block text-center w-full bg-white text-coolGray-800 font-bold rounded-lg py-2 mt-2"> {offer.statusMessage.linkTitle} </a> )}
      </div>
    );
  }

  function renderOfferRejectionReason() {
    if (!offer) return;
    if (offer?.role != 'buyer' || !offer.rejectReason) return;
    return (
      <div className="pt-4 w-full">
        <RejectReason rejectReason={offer?.rejectReason} />
      </div>
    )
  }

  function renderOfferStatusIcon() {
    if (!offer) return;
    switch (offer.status) {
      case "received":
      case "offered":
      case "accepted":
        return <OfferReceivedIcon />
      case "canceled":
        // TODO fix the icon for sold-out posts
        return <OfferCancelledIcon />
      case "rejected":
        return <ShobbakBoxIcon />
      case "blocked":
        return <ShobbakBoxIconError />
      default:
        return null;
    }
  }

  function renderNewActions() {
    // const buttonsCount = [...mainActions, ...normalActions, ...bottomActions].length
    return (
      <div>
        {mainActions.length > 0 && (
          <div className={`grid grid-cols-${mainActions} gap-3 ${mainActions.length ? "" : "px-4"}`}>
            {mainActions.map(action => {
              return (
                <div key={action} className="flex-1">
                  {renderOfferAction(action)}
                </div>
              )
            })}
          </div>
        )}

        {normalActions.length > 0 && (
          <div className={`mt-2 grid grid-cols-${normalActions} gap-3 px-4`}>
            {normalActions.map(action => {
              let disabled = actionLoading || (action == "call_for_pickup" && !offer?.flags.canCallForPickup)
              return (
                <div key={action}>
                  <button
                    disabled={disabled}
                    className={`w-full rounded-lg py-3 ${action === 'reject' ? 'text-coolGray-400 border border-coolGray-300' : 'bg-emerald-500 text-white'} ${disabled ? "bg-coolGray-300 text-white cursor-not-allowed" : ""}`}
                    onClick={() => {
                      if (action === 'reject') {
                        setRejectReasonModalVisible(true);
                      }else if (action === 'support'){
                        return  window.location.href = "https://shobbak.zendesk.com/hc/en-us"
                      }
                      else if (action === 'block') {
                        confirmAction(action);
                      } else if (action == "seller_handovers_buyer") {
                        setDeliveryConfirmationCodeVisible(true);
                      } else {
                        actionHandler(action);
                      }
                    }}
                  >
                    {
                      action === 'receive' &&
                        offer?.deliveryOption === 'shobbak_hub'
                        ? Translation.t(`buttons.pickup`)
                        : Translation.t(`buttons.offer_actions.${action}`)
                    }
                  </button>
                </div>
              )
            })}
          </div>
        )}

        {bottomActions.length > 0 && (
          <div className={`mt-2 grid grid-cols-${bottomActions} gap-3 px-4`}>
            {bottomActions.map(action => {
              return (
                <Button
                  key={action}
                  containerStyle="flex-1 rounded-lg border border-coolGray-300"
                  disabled={actionLoading}
                  size="medium"
                  type="clear"
                  color="coolGray-400"
                  label={Translation.t(`buttons.offer_actions.${action}`)}
                  onPress={() => {
                    if (action === 'start_dispute') {
                      setDisputeModalVisible(true);
                    } else {
                      confirmAction(action);
                    }
                  }}
                />
              )
            })}
          </div>
        )}
      </div>
    )
  }

  return (
    <Provider store={store}>
      <AppContext.Provider value={{ user: authUser.user, country: authUser.country, env }}>
        <Suspense fallback={Loader()}>
          <div className="md:block hidden">
            <Breadcrumb
              steps={{
                current: [
                  ...sidebar.filter((item) => item.active),
                  BREADCRUMB_ITEM,
                ],
              }}
              currentStep={BREADCRUMB_ITEM}
            />
          </div>
          <div className="container md:py-8 md:max-w-7xl mx-auto md:px-4 w-auto">
            <div className="flex flex-col-reverse md:flex-row md:space-s-6 items-start">
              {/* offer */}
              <div className="w-full md:w-2/3">
                <div className="bg-white">
                  {/* offer messages, under_title */}
                  {renderOfferMessages(offer?.messages, 'under_title')}

                  {/* offer extra status message */}
                  {offer?.extraStatusMessage != null && renderExtraStatusMessage()}

                  {/* offer status info */}
                  {renderOfferStatusInfo()}

                  {/* offer selected delivery method (after payment) (both buyer and seller) */}
                  {renderOfferSelectedDelivery()}

                  {/* shobbak delivery tracking (after payment) */}
                  {offer?.flags.showOrderSteps && (
                    <BriskItemTracking orderData={offer.deliveryOrderData} />
                  )}

                  {/* shipping company info (after payment) */}
                  {renderCourierShippingInfo()}

                  {/* select delivery option (for buyer) (before pament) */}
                  <div>
                    {offer?.flags?.setDeliveryOption && (
                      <DeliveryOptions
                        offer={offer}
                        currentCountry={authUser.country}
                        googleMapsApiKey={env.FRONTEND_GOOGLE_MAP_KEY}
                        offer_id={offer.id}
                        deliveryOption={offer.deliveryOption}
                        onDeliveryOptionChange={(option) => loadData()}
                        onAddressChange={(e) => loadData(e)}
                        flags={offer?.flags}
                        hubOrder={offer.hubOrder}
                        setDeliveryOptionAvailability={setDeliveryOptionAvailability}
                      />
                    )}


                    {/* mainActions used to be: send_offer, ship & pay, now *pay* is the only option */}
                    {/* {renderOldMainActionsImpl()} */}

                    {offer?.paymentMethods && !isEmpty(offer?.paymentMethods) && (
                      <PaymentMethodsDetails paymentMethods={offer.paymentMethods} currency={offer.currency} />
                    )}

                    {/* old impl for normalActions */}
                    {/* {renderOldNormalActionsImpl()} */}
                  </div>

                  {/* prices breakdown (both buyer and seller) */}
                  {offer?.flags.showPriceBreakdown && (
                    <PricesBreakDown
                      messages={offer?.messages || []}
                      lineItems={selectedPaymentMethod == "wallet" ? offer.withWalletLineItems : offer.lineItems}
                    />
                  )}

                  {renderNewActions()}
                </div>
              </div>

              {/* post */}
              <div className="w-full md:w-1/3 p-4 md:p-0">
                {renderDeliveryCode()}
                {offer && (
                  <div className="bg-white rounded-lg p-3 md:p-6">
                    <a href={offer.posting.publicSiteUrl}>
                      <PostingInfo
                        offer={offer}
                        community={community}
                      />
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div >


          {renderModals()}


          < RejectionReasonModal
            offerId={offerId}
            loadOffer={loadData}
            rejectReasonModalVisible={rejectReasonModalVisible}
            setRejectReasonModalVisible={setRejectReasonModalVisible}
            posting={posting}
            offer={offer}
          />


          <DropOffModal
            offerId={offerId}
            onSuccess={loadData}
            dropOffModalVisible={dropOffModalVisible}
            setDropOffModalVisible={setDropOffModalVisible}
          />


        </Suspense >
      </AppContext.Provider >
    </Provider >
  );
}

export default Offer;
