import React, { lazy, Suspense, useEffect, useRef, useState } from 'react'
import  DefaultButton  from "./DefaultButton";
import DefaultInput from "./DefaultInput";
import Translation from "../../helper/Translation";
import BackendCall from "../BackendCall";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";



const EditEmail = ({email ,locale,token,  emailConfirmation , onMobileChange, recaptchaEnabled = true})=> {
  const [isVerified , setIsVerified] = useState(emailConfirmation)
  const recaptchaRef = useRef(null)

  const resendVerificationCode = async ()=> {
    if(isVerified) return ;
    let params = {}
    if (recaptchaEnabled) {
      let recaptcha_token = await recaptchaRef.current.executeAsync()
      params = { ...params, recaptcha_token: recaptcha_token }
    }
    BackendCall
    .i({token , locale}).resendVerificationForDefaultMail(params).then(res=>  {
      setIsVerified(true)
      toast.success(Translation.t('texts.verification_mail_sent'))

      setTimeout(()=> {
        if (!emailConfirmation){
          setIsVerified(false)
        }
      }, 10000)
    })

  }
  return (
    <div className={`flex items-end gap-2 w-full`} >
      <DefaultInput containerClass={`w-full`} type={'email'} name={`email`} label={Translation.t('create_new_ad.email_address')} value={email} onValueChange={(value)=>{
        onMobileChange({ email: value })
        setIsVerified(true)
      }}/>
      {recaptchaEnabled && <ReCAPTCHA
          size="invisible"
          ref={recaptchaRef}
          sitekey={window.env?.RECAPTCHA_KEY}
        />
      }
      <DefaultButton label={Translation.t('buttons.verify')} disabled={isVerified} onButtonClick={()=>{ resendVerificationCode() }} />

    </div>
  );
}

export default EditEmail