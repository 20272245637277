import BackendCall, { HttpUnprocessableEntity } from '../../BackendCall';
import Translation from '../../../helper/Translation';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { showError } from '../../../helper/errors';
import Divider from '../../Divider';
import Button from '../../Button';
import EventDispatcher from '../../../helper/EventDispatcher';
import OfferGiftCard from '../OfferGiftCard';
import { GiftCard, PostingOffer } from "@shobbak/react-services/dist/Entities";
import PaymentButtons from "../../PaymentButtons";
import { ApplePay } from '../../../helper/ApplePay';
import { PaymentMethodName } from '../../PaymentMethodsList';

type Props = {
  isDisabled?: boolean;
  offer: PostingOffer;
  onSuccess?: (offer: PostingOffer) => void;
  onError?: (message: string) => void;
  onUpdate?: (newOffer: any) => void;
  selectedGiftCard: string | undefined;
  selectedGiftCardObj?: any;
  onPaymentMethodUpdate?: (method: string) => any;
};

export type HandlePaymentParams = {
  card_id?: string;
  payment_handler: PaymentMethodName;
  payment_method: any;
  payment_data?: any;
  transaction_data?: any;
  card_cvv?: string;
  charge_wallet_too?: boolean;
  request_from?: 'web'
};

const PaymentAction = ({
  isDisabled = false,
  offer,
  onSuccess,
  onError,
  onUpdate,
  selectedGiftCard,
  selectedGiftCardObj,
  onPaymentMethodUpdate,
}: Props) => {
  const [giftCards, setGiftCards] = React.useState<GiftCard[]>();
  const [isProcessingPayment, setIsProcessingPayment] =
    useState<boolean>(false);

  React.useEffect(() => {
    BackendCall.i()
      .getUserGifts({ filter: { usage: 'available' } })
      .then((gifts) => {
        setGiftCards(gifts);
      })
      .catch((error) => {
      });
  }, [offer]);

  React.useEffect(() => {
    const listener = EventDispatcher.on(
      'payment-callback',
      ({ params }) => {
        setIsProcessingPayment(false);
        if (params?.payment_result === 'failed') {
          const message = Translation.t('texts.offer_update_failed');
          toast.error(message);
          return onError?.(message);
        }

        BackendCall.i()
          .getPostingOffers(offer.id, { user_shobbak_card_id: selectedGiftCard })
          .then((offer) => {
            toast.success(
              Translation.t('texts.offer_updated')
            );
            onSuccess?.(offer);
          });
      },
    );
    return () => {
      if (typeof listener === 'string') {
        EventDispatcher.remove('payment-callback', listener);
      }
    };
  }, []);

  const applyGiftCard = (giftCardId: string | undefined) => {
    BackendCall.i()
      .actionPostingOffers(offer.id, 'set_gift_card', {
        user_shobbak_card_id: giftCardId,
      })
      .then((res) => {
        onUpdate?.(res);
      })
      .catch((error) => showError(error));
  };

  const handlePayment = async (data: HandlePaymentParams) => {
    if (data.payment_handler === 'web_pay') {
      setIsProcessingPayment(true);
      if (offer.webPaymentUrl) {
        window.open(offer.webPaymentUrl)
      }
      setIsProcessingPayment(false);
    } else {
      setIsProcessingPayment(true);
      BackendCall.i()
        .actionPostingOffers(offer.id, 'pay', {
          ...data,
          user_shobbak_card_id: selectedGiftCard,
        })
        .then(async (offer) => {
          if (
            offer.payment3Ds.shouldRedirect &&
            data.payment_handler === 'card_pay'
          ) {
            window.open(offer.payment3Ds.url)
          } else {
            if (data.payment_handler === 'apple_pay') {
              ApplePay.complete(ApplePay.SUCCESS);
            }

            //update offer
            toast.success(
              Translation.t('texts.offer_updated')
            );
            onSuccess?.(offer);
            // added to reload the page after the payment process is completed
            // TODO: add it to the onSuccess callback
            window.location.reload()
          }
        })
        .catch(async (error) => {
          setIsProcessingPayment(false);
          if (data.payment_handler === 'apple_pay') {
            ApplePay.complete(ApplePay.FAILURE);
          }
          let message = Translation.t('texts.offer_update_failed');
          if (error instanceof HttpUnprocessableEntity) {
            message = error.messageStr();
          }
          toast.error(message);
          onError?.(message);
        })
        .finally(() => {
          setIsProcessingPayment(false);
        });
    }
  };
  const payByGiftCard = () => {
    setIsProcessingPayment(true);
    BackendCall.i()
      .actionPostingOffers(offer.id, 'pay', {
        user_shobbak_card_id: selectedGiftCard,
      })
      .then(async (offer) => {
        setIsProcessingPayment(false);
        onSuccess?.(offer);
      })
      .catch(async (error) => {
        setIsProcessingPayment(false);

        let message = Translation.t('texts.offer_update_failed');
        if (error instanceof HttpUnprocessableEntity) {
          message = error.messageStr();
        }
        toast.error(message);
        onError?.(message);
      })
      .finally(() => {
        setIsProcessingPayment(false);
      });
  };

  const handleError = (message) => {
    setIsProcessingPayment(false);
    toast.error(message);
    onError?.(message);
  };
  const {
    cardPayAllowed,
    applePayAllowed,
    webPayAllowed,
    walletPayAllowed,
    canPayByWallet,
  } = offer;
  return (
    <div className="flex-1">
      {giftCards && giftCards?.length > 0 && (
        <div className="p-4">
          <h3 className="mb-4 text-lg font-bold text-coolGray-900">{Translation.t('texts.available_gifts')}</h3>
          <div className="flex-1">
            <div
              className="flex-1 grid grid-cols-3 gap-4"
            >
              {giftCards.map((gift) => (
                <OfferGiftCard
                  selected={String(selectedGiftCard) === String(gift.id)}
                  gift={gift}
                  key={gift.id}
                  onPress={() => {
                    applyGiftCard(
                      String(selectedGiftCard) === String(gift.id)
                        ? undefined
                        : gift.id,
                    );
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      )}

      <PaymentButtons
        isDisabled={isDisabled}
        amount={offer?.totalAmountPayable}
        offerAmount={offer?.offeredAmountDetail}
        deliveryFee={offer?.deliveryFeeDetail}
        currency={offer?.currency}
        paymentOptions={offer ? offer.paymentOptions : []}
        onSuccess={handlePayment}
        onFailure={handleError}
        isProcessingPayment={isProcessingPayment}
        onStartPayment={() => setIsProcessingPayment(true)}
        onEndPayment={() => setIsProcessingPayment(false)}
        readyToPay={offer?.flags?.buyerCanPay}
        walletPayAllowed={walletPayAllowed}
        cardPayAllowed={cardPayAllowed}
        applePayAllowed={ApplePay.isAvailable() && applePayAllowed}
        canPayByWallet={canPayByWallet}
        webPayAllowed={!cardPayAllowed && !(ApplePay.isAvailable() && applePayAllowed) && webPayAllowed}
        lineItems={offer?.lineItems}
        withWalletLineItems={offer?.withWalletLineItems}
        messages={offer?.messages}
        coveredByGiftCard={offer?.coveredByGift}
        offer={offer}
        selectedGiftCard={selectedGiftCardObj}
        onPaymentMethodUpdate={onPaymentMethodUpdate}
      />

      {/* {offer?.coveredByGift && (
        <Button
          loading={isProcessingPayment}
          label={Translation.t('buttons.pay')}
          type="solid"
          containerStyle="mx-4 w-full"
          onPress={payByGiftCard}
        />
      )} */}

    </div>
  );
};

export default PaymentAction;
