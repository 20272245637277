import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import BackendCall from "../src/Components/BackendCall";
const ProfileLayout = lazy(() => import("../src/Components/ProfileLayout"))
const ProfileCard = lazy(() => import("../src/Components/profile/ProfileCard"))
import Pagination from '../src/Components/Pagination'
import Loader from "../src/Components/icon/Loader";
import Translation from '../src/helper/Translation'
import StatusGroups from "../src/Components/StatusGroups";
import 'remixicon/fonts/remixicon.css'

import isEmpty from 'lodash/isEmpty'
import { PostingOffer } from "@shobbak/react-services/dist/Entities";
import OfferSummaryCard from "../src/Components/profile/OfferSummaryCard";

export default ({ locale, token }) => {
  BackendCall.i({ token, locale })

  const [statusGroup, setStatusGroup] = useState<string[]>([]);
  const [isAscSorting, setIsAscSorting] = useState(false);
  const [offers, setOffers] = useState<PostingOffer[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [meta, setMeta] = useState({})
  const offerRefs = useRef(offers)

  const setOffersWithRefs = (offers) => {
    setOffers(offers)
    offerRefs.current = offers
  }

  const getOffers = ({ page }) => {
    setIsLoading(true)
    BackendCall.i().getPostingOffersIndex({
      page: { number: page, size: 15 },
      filter: { status_group: statusGroup },
      sort: { created_at: isAscSorting ? 'asc' : 'desc' },
    }).then(({ records, meta }) => {
      setOffersWithRefs(records)
      setMeta(meta)
      setIsLoading(false)
    }).catch(res => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    getOffers({ page: 1 })
  }, [currentPage, statusGroup, isAscSorting])

  const onSelectStatusGroup = (data) => {
    setStatusGroup(Object.keys(data));
  };

  return (
    <Suspense fallback={Loader()}>
      <ProfileLayout>
        <ProfileCard title={Translation.t('breadcrumb.my_offers')}>
          <div className="flex flex-col">

            <div className="flex items-center justify-between space-s-4">
              <StatusGroups
                onSelect={onSelectStatusGroup}
              />
              <button
                onClick={() => setIsAscSorting(!isAscSorting)}
                className="flex items-center justify-center"
              >
                <i className={`${isAscSorting ? 'ri-sort-asc' : 'ri-sort-desc'} ri-lg`} />
              </button>
            </div>
            <hr />


            {isLoading && <Loader />}


            {!isLoading && (
              <>
                <div className="flex flex-col space-y-2 my-4">
                  {offers.map((offer) => {
                    return (
                      <div key={offer.id}>
                        <OfferSummaryCard offer={offer}></OfferSummaryCard>
                      </div>
                    )
                  })}

                </div>

                {offers.length == 0 && (
                  <div
                    className="p-2 flex items-center justify-center w-full">{Translation.t('posting_offer.tabs.not_results')}
                  </div>
                )}

                {!isEmpty(meta) && offers.length != 0 && (
                  <Pagination {...meta} onPaginationChange={({ page }) => {
                    getOffers({ page })
                  }} />
                )}
              </>
            )}
          </div>
        </ProfileCard>
      </ProfileLayout>
    </Suspense>
  );
}
