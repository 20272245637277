import React, { lazy, Suspense, useEffect, useRef, useState } from 'react'

const  DefaultButton = ({label , disabled ,containerClass ='', onButtonClick})=> {
  return <button className={`${disabled ? 'cursor-not-allowed bg-coolGray-300': 'cursor-pointer bg-emerald-600'} ${containerClass} whitespace-nowrap  border border-transparent rounded-md h-9 py-1.5 px-4 items-center justify-center text-sm font-normal leading-5 text-white hover:bg-emerald-700 active:bg-emerald-600`} onClick={onButtonClick}>{label}</button>
}

export const AlertButton = ({ label , containerClass ='', onButtonClick  }) => {
  return <button className={` cursor-pointer bg-white text-red-500 whitespace-nowrap  border border-transparent border-red-500 rounded-md ${containerClass} py-1.5 px-4 items-center justify-center
   text-sm font-normal leading-5`} onClick={onButtonClick}>{label}</button>

}

export const DeleteButton = ({ label , containerClass ='', onButtonClick  }) => {
  return <button className={` cursor-pointer bg-red-500 text-white whitespace-nowrap  border border-transparent border-red-500 rounded-md ${containerClass} py-1.5 px-4 items-center justify-center
   text-sm font-normal leading-5`} onClick={onButtonClick}>{label}</button>
}

export const ButtonElement = ({ className , onButtonClick , label }) => {
  return <button className={` ${className} cursor-pointer   whitespace-nowrap  border border-transparent  rounded-md py-1.5 px-4 items-center justify-center text-sm font-normal leading-5`} onClick={onButtonClick}>{label}</button>
}

export default  DefaultButton
