import React from "react";

export default function () {
  return (
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M92 48.227v-21.08a6.813 6.813 0 0 0-5.213-6.667l-34.56-8.32a6.812 6.812 0 0 0-8.413 6.667v66.507A6.815 6.815 0 0 0 52.227 92L86.8 83.56a6.813 6.813 0 0 0 5.214-6.666v-2.667" stroke="#F9C12A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M92 55.614a1.333 1.333 0 0 0 0-2.56 1.334 1.334 0 0 0 0 2.56Zm0 4.746a1.333 1.333 0 0 0 0-2.56 1.334 1.334 0 0 0 0 2.56Zm0 4.76a1.333 1.333 0 0 0 0-2.56 1.333 1.333 0 0 0 0 2.56Zm0 4.76a1.333 1.333 0 0 0 0-2.56 1.334 1.334 0 0 0 0 2.56Zm-64.627-55.6 2.854-9.333.413-1.333c.306-.974-1.227-1.334-1.52-.427l-2.854 9.333-.413 1.334c-.306.973 1.213 1.333 1.52.426Zm-24.32 1.453L12 19.573l1.253.533a.813.813 0 0 0 1.08-.28.787.787 0 0 0-.28-1.08l-8.947-3.84-1.253-.546a.84.84 0 0 0-1.08.28.813.813 0 0 0 .28 1.093Z" fill="#10B981"/>
      <path d="m14.977 4.74 2.95 9.179v.013l.023.074c.033.103.011.151-.006.178a.31.31 0 0 1-.18.114.384.384 0 0 1-.25-.012.269.269 0 0 1-.145-.173l-.411-1.327-.002-.005-2.972-9.249c-.038-.129-.011-.19.006-.219a.258.258 0 0 1 .156-.103.36.36 0 0 1 .24.017.33.33 0 0 1 .169.193l.422 1.32Z" stroke="#F9C12A"/>
      <path d="M18.08 44.786v31.027l25.733 6.2V37.986l-25.733 6.8Z" fill="#fff" stroke="#10B981" stroke-width="2" stroke-linejoin="round"/>
      <path d="m18.08 44.786-11.16-9.08L39.693 24.6l4.12 13.386-25.733 6.8Zm51.454 0v31.027l-25.72 6.2V37.986l25.72 6.8Z" fill="#fff" stroke="#10B981" stroke-width="2" stroke-linejoin="round"/>
      <path d="m69.534 44.786 11.173-9.08L47.934 24.6l-4.12 13.386 25.72 6.8Z" fill="#fff" stroke="#10B981" stroke-width="2" stroke-linejoin="round"/>
      <path d="M49.573 60.187a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm0 1.626a.32.32 0 1 1-.32.32.334.334 0 0 1 .32-.32Zm.813-.813a.333.333 0 0 1 .182.564.32.32 0 0 1-.235.089.32.32 0 0 1-.32-.32.32.32 0 0 1 .373-.333Zm-1.612 0a.32.32 0 0 1 0 .64.32.32 0 0 1-.334-.32.334.334 0 0 1 .334-.32Zm1.612 1.667a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32Zm-1.612-.001a.32.32 0 1 1-.027.64.32.32 0 0 1 .027-.64ZM48 60.186a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653Zm0 1.628a.32.32 0 1 1-.026.639.32.32 0 0 1 .026-.64Zm1.573 1.626a.32.32 0 0 1 .333.32.333.333 0 0 1-.653 0 .32.32 0 0 1 .32-.32Zm-1.573 0a.32.32 0 0 1 .32.32.334.334 0 0 1-.653 0 .32.32 0 0 1 .333-.32Zm1.573-6.507a.333.333 0 1 1 .027.665.333.333 0 0 1-.027-.665Zm0 1.627a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm.814-.814a.332.332 0 0 1 .075.661.333.333 0 1 1-.075-.66Zm-1.613.001a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm1.613 1.626a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm-1.613 0a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653ZM48 56.933a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm0 1.627a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Z" fill="#10B981"/>
      <path d="M49.573 60.187a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666ZM48 60.186a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653Zm1.574-6.506a.333.333 0 1 1 .132.653.333.333 0 0 1-.132-.654Zm0 1.626a.332.332 0 0 1 .4.327.335.335 0 0 1-.4.327.333.333 0 0 1 0-.654Zm.813-.813a.333.333 0 1 1 .133.653.333.333 0 0 1-.133-.653Zm-1.613 0a.333.333 0 1 1 0 .667.333.333 0 0 1 0-.667Zm1.613 1.627a.333.333 0 1 1 .026.665.333.333 0 0 1-.026-.665Zm-1.613 0a.333.333 0 1 1-.133.652.333.333 0 0 1 .133-.652ZM48 53.68a.333.333 0 1 1 0 .665.333.333 0 0 1 0-.665Zm0 1.627a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Zm1.573 1.626a.333.333 0 1 1 .027.665.333.333 0 0 1-.027-.665Zm-1.573 0a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm1.573-6.493a.32.32 0 0 1 .333.32.333.333 0 0 1-.653 0 .32.32 0 0 1 .32-.32Zm.001 1.56a.335.335 0 0 1 .4.327.334.334 0 1 1-.4-.327Zm.812-.747a.322.322 0 0 1 .334.32.333.333 0 0 1-.654 0 .32.32 0 0 1 .32-.32Zm-1.612 0a.32.32 0 0 1 .32.32.333.333 0 0 1-.654 0 .32.32 0 0 1 .334-.32Zm1.613 1.614a.333.333 0 1 1 .133.653.333.333 0 0 1-.133-.653Zm-1.613-.001a.333.333 0 1 1 0 .667.333.333 0 0 1 0-.667ZM48 50.44a.32.32 0 0 1 .32.32.334.334 0 0 1-.653 0 .32.32 0 0 1 .333-.32ZM48 52a.333.333 0 1 1 0 .667.333.333 0 0 1 0-.667Z" fill="#10B981"/>
      <path d="M49.574 53.68a.333.333 0 1 1 .132.653.333.333 0 0 1-.132-.654Zm-1.574 0a.333.333 0 1 1 0 .665.333.333 0 0 1 0-.665Zm-1.667 6.506a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653Zm1.667 0a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653Zm-1.667 1.628a.32.32 0 1 1-.026.639.32.32 0 0 1 .026-.64Zm1.667 0a.32.32 0 1 1-.026.639.32.32 0 0 1 .026-.64ZM47.147 61a.32.32 0 0 1 0 .64.32.32 0 0 1-.334-.32.333.333 0 0 1 .334-.32Zm-1.627 0a.32.32 0 0 1 0 .64.32.32 0 0 1-.334-.32.334.334 0 0 1 .334-.32Zm1.627 1.666a.32.32 0 1 1-.027.64.32.32 0 0 1 .027-.64Zm-1.627 0a.32.32 0 1 1-.027.64.32.32 0 0 1 .027-.64Zm.813.774a.32.32 0 0 1 .32.32.333.333 0 0 1-.653 0 .32.32 0 0 1 .333-.32Zm1.667 0a.32.32 0 0 1 .32.32.334.334 0 0 1-.653 0 .32.32 0 0 1 .333-.32Zm-1.667-6.507a.333.333 0 1 1-.132.654.333.333 0 0 1 .132-.654Zm1.667 0a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-1.667 1.627a.333.333 0 1 1-.132.654.333.333 0 0 1 .132-.654Zm1.667 0a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-.853-.813a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-1.627 0a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm1.627 1.626a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-1.627 0a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Z" fill="#10B981"/>
      <path d="M46.333 60.186a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653Zm1.667 0a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653Zm-1.667-6.506a.333.333 0 1 1 0 .665.333.333 0 0 1 0-.665Zm1.667 0a.333.333 0 1 1 0 .665.333.333 0 0 1 0-.665Zm-1.667 1.627a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Zm1.667 0a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Zm-.853-.814a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm-1.627 0a.333.333 0 1 1 0 .667.333.333 0 0 1 0-.667Zm1.627 1.627a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm-1.627 0a.333.333 0 1 1-.133.652.333.333 0 0 1 .133-.652Zm.813.813a.333.333 0 1 1-.132.654.333.333 0 0 1 .132-.654Zm1.667 0a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-1.667-6.493a.32.32 0 0 1 .32.32.333.333 0 0 1-.653 0 .32.32 0 0 1 .333-.32Zm1.667 0a.32.32 0 0 1 .32.32.334.334 0 0 1-.653 0 .32.32 0 0 1 .333-.32ZM46.333 52a.333.333 0 1 1 0 .667.333.333 0 0 1 0-.667ZM48 52a.333.333 0 1 1 0 .667.333.333 0 0 1 0-.667Zm-.853-.747a.32.32 0 0 1 .32.32.334.334 0 0 1-.654 0 .319.319 0 0 1 .334-.32Zm-1.627 0a.32.32 0 0 1 .32.32.333.333 0 0 1-.654 0 .32.32 0 0 1 .334-.32Zm1.627 1.613a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm-1.627 0a.333.333 0 1 1 0 .667.333.333 0 0 1 0-.667Z" fill="#10B981"/>
      <path d="M46.333 53.68a.333.333 0 1 1 0 .665.333.333 0 0 1 0-.665Zm1.667 0a.333.333 0 1 1 0 .665.333.333 0 0 1 0-.665Zm2.307-4.08a.321.321 0 0 1 .32.333.32.32 0 0 1-.32.32.333.333 0 0 1 0-.653Zm-1.627 0a.333.333 0 1 1 .133.653.333.333 0 0 1-.133-.653Z" fill="#10B981"/>
      <path d="M50.307 49.6a.321.321 0 0 1 .32.333.32.32 0 0 1-.32.32.333.333 0 0 1 0-.653Zm-3.254 0a.333.333 0 1 1 .133.654.333.333 0 0 1-.133-.654Zm-1.626 0a.333.333 0 1 1 .133.653.333.333 0 0 1-.133-.653Z" fill="#10B981"/>
      <path d="M47.053 49.6a.333.333 0 1 1 .133.654.333.333 0 0 1-.133-.654Zm-1.626 18.52a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm0-1.626a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm.813.812a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665Zm0-1.626a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm.813 2.44a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm0-1.626a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665Zm-1.626-1.627a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm1.626 0a.333.333 0 1 1 .132-.652.333.333 0 0 1-.132.652Zm-1.626 6.493a.32.32 0 1 1 .026-.639.32.32 0 0 1-.026.64Zm-.001-1.626a.32.32 0 0 1-.32-.32.333.333 0 0 1 .654 0 .32.32 0 0 1-.334.32Zm.814.813a.32.32 0 0 1-.32-.32.334.334 0 0 1 .653 0 .32.32 0 0 1-.333.32Zm0-1.614a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm.814 2.427a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Zm-.001-1.626a.32.32 0 0 1-.32-.32.334.334 0 0 1 .654 0 .319.319 0 0 1-.334.32Z" fill="#10B981"/>
      <path d="M45.427 68.12a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm1.626 0a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm-1.626 6.547a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm0-1.68a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm.813.813a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm0-1.626a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.333.333 0 0 1-.333.32Zm.813 2.493a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm0-1.68a.333.333 0 1 1 .132-.652.333.333 0 0 1-.132.652Zm-1.626-1.627a.32.32 0 1 1 .026-.639.32.32 0 0 1-.026.64Zm1.627 0a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Zm-1.627 6.507a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm0-1.627a.334.334 0 1 1 .133-.654.334.334 0 0 1-.133.654Zm.813.814a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm0-1.627a.334.334 0 1 1 .132-.654.334.334 0 0 1-.132.654Zm.813 2.44a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm0-1.627a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Z" fill="#10B981"/>
      <path d="M45.427 74.667a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm1.626 0a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm1.627-6.547a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm-1.627 0a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm1.627-1.626a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm-1.627 0a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665Zm.814.812a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm1.626 0a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665Zm-1.626-1.626a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm1.626 0a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm.814 2.44a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm0-1.627a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm-1.627-1.626a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.627 0a.333.333 0 1 1 .132-.652.333.333 0 0 1-.132.652Zm3.254-.001a.333.333 0 0 1 0-.653.321.321 0 0 1 .32.333.32.32 0 0 1-.32.32ZM48.68 71.36a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Zm-1.626 0a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Zm1.626-1.626a.32.32 0 0 1-.32-.32.333.333 0 0 1 .654 0 .32.32 0 0 1-.334.32Zm-1.627 0a.32.32 0 0 1-.32-.32.334.334 0 0 1 .654 0 .319.319 0 0 1-.334.32Zm.814.813a.32.32 0 0 1-.32-.32.334.334 0 0 1 .653 0 .32.32 0 0 1-.333.32Zm1.626 0a.32.32 0 0 1-.32-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.333.32Zm-1.626-1.614a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm1.626 0a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm.813 2.427a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm0-1.626a.32.32 0 1 1 .32-.32.307.307 0 0 1-.32.32Z" fill="#10B981"/>
      <path d="M48.68 68.12a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm-1.627 0a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm3.254 0a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm-1.627 6.547a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.627 0a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm1.627-1.68a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.627 0a.333.333 0 1 1 .132-.652.333.333 0 0 1-.132.652Zm.814.813a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm1.627 0a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.627-1.626a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.333.333 0 0 1-.333.32Zm1.626 0a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.334.334 0 0 1-.333.32Zm.814 2.493a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0-1.681a.333.333 0 0 1 0-.653.321.321 0 0 1 .32.333.32.32 0 0 1-.32.32ZM48.68 71.36a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Zm-1.626 0a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Zm3.252 0a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm-1.626 6.507a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm-1.627 0a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm1.627-1.627a.334.334 0 1 1 .133-.654.334.334 0 0 1-.133.654Zm-1.627 0a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Zm.814.814a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm1.627 0a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.627-1.627a.333.333 0 1 1 .132-.654.333.333 0 0 1-.132.654Zm1.627 0a.334.334 0 1 1 .132-.654.334.334 0 0 1-.132.654Zm.813 2.439a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm0-1.626a.333.333 0 0 1 0-.653.321.321 0 0 1 .32.333.32.32 0 0 1-.32.32Z" fill="#10B981"/>
      <path d="M48.68 74.667a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.627 0a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm3.254 0a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0-6.547a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm0-1.627a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm0-1.627a.333.333 0 0 1 0-.653.321.321 0 0 1 .32.333.32.32 0 0 1-.32.32Zm-.001 6.494a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm0-1.626a.32.32 0 1 1 .32-.32.307.307 0 0 1-.32.32Z" fill="#10B981"/>
      <path d="M50.307 68.12a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm0 6.547a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0-1.681a.333.333 0 0 1 0-.653.321.321 0 0 1 .32.333.32.32 0 0 1-.32.32Zm-.001-1.626a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm.001 6.506a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm0-1.626a.333.333 0 0 1 0-.653.321.321 0 0 1 .32.333.32.32 0 0 1-.32.32Z" fill="#10B981"/>
      <path d="M50.307 74.667a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm-3.974 5.653a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm-.813-.814a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm1.626 0a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm.854.814a.333.333 0 1 1-.027-.665.333.333 0 0 1 .027.665Zm-1.666-1.654a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm1.666 0a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm.773.84a.334.334 0 1 1-.026-.667.334.334 0 0 1 .026.667Zm1.614.001a.333.333 0 1 1 .133-.654.333.333 0 0 1-.133.654Zm-.813-.84a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Z" fill="#10B981"/>
      <path d="M48 78.666a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm6.453-18.479a.333.333 0 1 1-.32.32.32.32 0 0 1 .32-.32Zm.001 1.626a.32.32 0 1 1 0 .64.32.32 0 0 1 0-.64Zm.813-.813a.32.32 0 0 1 0 .64.307.307 0 0 1-.32-.32.32.32 0 0 1 .32-.32Zm-1.627 0a.32.32 0 0 1 .32.32.307.307 0 0 1-.32.32.32.32 0 0 1 0-.64Zm1.626 1.667a.32.32 0 1 1 0 .64.32.32 0 0 1 0-.64Zm-1.626 0a.32.32 0 1 1 0 .64.32.32 0 0 1 0-.64Zm-.813-2.48a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm0 1.626a.32.32 0 1 1-.32.32.334.334 0 0 1 .32-.32Zm1.627 1.627a.32.32 0 1 1 0 .64.32.32 0 0 1 0-.64Zm-1.627 0a.32.32 0 0 1 .333.32.334.334 0 0 1-.653 0 .32.32 0 0 1 .32-.32Zm1.626-6.507a.333.333 0 1 1-.32.32.32.32 0 0 1 .32-.32Zm0 1.627a.333.333 0 1 1-.32.32.32.32 0 0 1 .32-.32Zm.814-.813a.333.333 0 0 1 0 .653.321.321 0 0 1-.32-.333.32.32 0 0 1 .32-.32Zm-1.627 0a.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333.333.333 0 0 1 0-.653Zm1.627 1.626a.333.333 0 0 1 0 .653.321.321 0 0 1-.32-.333.32.32 0 0 1 .32-.32Zm-1.627 0a.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333.333.333 0 0 1 0-.653Zm-.813-2.44a.333.333 0 1 1 .027.665.333.333 0 0 1-.027-.665Zm0 1.627a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Z" fill="#10B981"/>
      <path d="M54.453 60.187a.333.333 0 1 1-.32.32.32.32 0 0 1 .32-.32Zm-1.626 0a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm1.627-6.507a.334.334 0 1 1 .027.667.334.334 0 0 1-.027-.667Zm0 1.627a.333.333 0 1 1 .027.665.333.333 0 0 1-.027-.665Zm.813-.814a.333.333 0 0 1 0 .654.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.334Zm-1.627 0a.32.32 0 0 1 .32.334.32.32 0 0 1-.32.32.333.333 0 0 1 0-.654Zm1.627 1.627a.333.333 0 0 1 0 .654.321.321 0 0 1-.32-.334.32.32 0 0 1 .32-.32Zm-1.627 0a.32.32 0 0 1 .32.32.32.32 0 0 1-.32.334.333.333 0 0 1 0-.654Zm-.813-2.44a.333.333 0 1 1 .132.652.333.333 0 0 1-.132-.653Zm0 1.626a.331.331 0 0 1 .367.184.333.333 0 1 1-.367-.184Zm1.626 1.627a.333.333 0 1 1-.32.32.32.32 0 0 1 .32-.32Zm-1.626 0a.333.333 0 1 1 .027.665.333.333 0 0 1-.027-.665Zm1.627-6.493a.32.32 0 1 1 0 .64.32.32 0 0 1 0-.64Zm0 1.56a.334.334 0 1 1 .027.667.334.334 0 0 1-.027-.667Zm.813-.747a.32.32 0 1 1-.32.32.307.307 0 0 1 .32-.32Zm-1.627 0a.307.307 0 0 1 .32.32.32.32 0 1 1-.32-.32Zm1.627 1.613a.333.333 0 0 1 0 .654.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.334Zm-1.627 0a.321.321 0 0 1 .32.334.32.32 0 0 1-.32.32.333.333 0 0 1 0-.654Zm-.813-2.426a.32.32 0 0 1 .333.32.334.334 0 0 1-.653 0 .32.32 0 0 1 .32-.32Zm0 1.56a.334.334 0 0 1 .367.47.334.334 0 1 1-.367-.47Z" fill="#10B981"/>
      <path d="M54.454 53.68a.334.334 0 1 1 .027.667.334.334 0 0 1-.027-.667Zm-1.627 0a.333.333 0 1 1 .132.652.333.333 0 0 1-.132-.653ZM51.2 60.187a.334.334 0 1 1 .026.667.334.334 0 0 1-.026-.667Zm1.627 0a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666ZM51.2 61.813a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32Zm1.627 0a.32.32 0 1 1-.32.32.334.334 0 0 1 .32-.32ZM52 61a.333.333 0 0 1 .333.32.319.319 0 0 1-.333.32.32.32 0 1 1 0-.64Zm0 1.667a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32Zm-.8.773a.32.32 0 0 1 .333.32.334.334 0 0 1-.653 0 .321.321 0 0 1 .32-.32Zm1.627 0a.32.32 0 0 1 .333.32.334.334 0 0 1-.653 0 .32.32 0 0 1 .32-.32ZM51.2 56.933a.333.333 0 1 1 .027.665.333.333 0 0 1-.027-.665Zm1.627 0a.333.333 0 1 1 .027.665.333.333 0 0 1-.027-.665ZM51.2 58.56a.334.334 0 1 1 .026.667.334.334 0 0 1-.026-.667Zm1.627 0a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666ZM52 57.746a.333.333 0 1 1 .027.665.333.333 0 0 1-.027-.665Zm0 1.627a.334.334 0 1 1 .026.667.334.334 0 0 1-.026-.667Z" fill="#10B981"/>
      <path d="M51.2 60.187a.334.334 0 1 1 .026.667.334.334 0 0 1-.026-.667Zm1.627 0a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666ZM51.2 53.68a.333.333 0 1 1 .132.653.333.333 0 0 1-.132-.654Zm1.627 0a.333.333 0 1 1 .132.652.333.333 0 0 1-.132-.653ZM51.2 55.306a.332.332 0 0 1 .4.327.333.333 0 1 1-.4-.327Zm1.627 0a.331.331 0 0 1 .367.184.333.333 0 1 1-.367-.184ZM52 54.493a.333.333 0 1 1 .133.653.333.333 0 0 1-.133-.653Zm0 1.627a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm-.8.813a.333.333 0 1 1 .027.665.333.333 0 0 1-.027-.665Zm1.627 0a.333.333 0 1 1 .027.665.333.333 0 0 1-.027-.665ZM51.2 50.44a.32.32 0 0 1 .333.32.334.334 0 0 1-.653 0 .32.32 0 0 1 .32-.32Zm1.627 0a.32.32 0 0 1 .333.32.334.334 0 0 1-.653 0 .32.32 0 0 1 .32-.32ZM51.2 52a.335.335 0 0 1 .4.327.333.333 0 1 1-.4-.327Zm1.627 0a.334.334 0 0 1 .367.47.334.334 0 1 1-.367-.47ZM52 51.253a.32.32 0 0 1 .333.32.334.334 0 0 1-.653 0 .32.32 0 0 1 .32-.32Zm0 1.614a.333.333 0 1 1 .133.653.333.333 0 0 1-.133-.653Z" fill="#10B981"/>
      <path d="M51.2 53.68a.333.333 0 1 1 .132.653.333.333 0 0 1-.132-.654Zm1.627 0a.333.333 0 1 1 .132.652.333.333 0 0 1-.132-.653Zm2.36-4.08a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Zm-1.627 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Z" fill="#10B981"/>
      <path d="M55.187 49.6a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666ZM52 49.6a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Z" fill="#10B981"/>
      <path d="M52 49.6a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Zm-.88 17.707a.319.319 0 0 1-.32-.334.32.32 0 0 1 .32-.32.333.333 0 0 1 0 .654Zm0-1.627a.32.32 0 0 1-.32-.346.32.32 0 0 1 .32-.32.334.334 0 0 1 0 .653v.013Zm.88 2.44a.334.334 0 0 1-.367-.47.333.333 0 1 1 .367.47Zm0-1.627a.333.333 0 1 1-.132-.652.333.333 0 0 1 .132.652Zm0-1.626a.333.333 0 1 1-.026-.667.333.333 0 0 1 .026.667Zm-.88 5.679a.305.305 0 0 1-.32-.32.32.32 0 1 1 .32.32Zm0-1.612a.32.32 0 0 1-.32-.334.32.32 0 0 1 .32-.32.333.333 0 0 1 0 .654ZM52 71.36a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm0-1.626a.32.32 0 0 1-.333-.32.334.334 0 0 1 .653 0 .32.32 0 0 1-.32.32Z" fill="#10B981"/>
      <path d="M52 68.12a.334.334 0 0 1-.367-.47.333.333 0 1 1 .367.47Zm-.88 5.68a.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.334.333.333 0 0 1 0 .654Zm0-1.626a.32.32 0 0 1-.32-.32.307.307 0 0 1 .32-.32.32.32 0 1 1 0 .64Zm.88 2.492a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm0-1.679a.333.333 0 1 1-.027-.665.333.333 0 0 1 .027.665Zm0-1.627a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm-.88 5.694a.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.334.333.333 0 0 1 0 .654Zm0-1.627a.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.334.333.333 0 0 1 0 .654Zm.88 2.44a.333.333 0 1 1-.133-.652.333.333 0 0 1 .133.652Zm0-1.627a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Z" fill="#10B981"/>
      <path d="M52 74.666a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm1.56-6.546a.334.334 0 0 1-.368-.47.334.334 0 1 1 .368.47Zm-1.56 0a.334.334 0 0 1-.367-.47.333.333 0 1 1 .367.47Zm1.56-1.627a.333.333 0 1 1-.133-.652.333.333 0 0 1 .133.652Zm-1.56 0a.333.333 0 1 1-.132-.652.333.333 0 0 1 .132.652Zm.746.813a.333.333 0 1 1-.132-.652.333.333 0 0 1 .132.653Zm1.627 0a.333.333 0 1 1-.132-.653.333.333 0 0 1 .132.653Zm-1.627-1.626a.331.331 0 0 1-.367-.184.334.334 0 0 1 .367-.47.334.334 0 0 1 0 .654Zm1.627 0a.332.332 0 0 1-.4-.327.333.333 0 1 1 .4.327Zm.814 2.44a.335.335 0 0 1-.4-.327.333.333 0 1 1 .4.327Zm0-1.627a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm-1.627-1.626a.334.334 0 1 1-.026-.668.334.334 0 0 1 .026.668Zm-1.56 0a.333.333 0 1 1-.026-.667.333.333 0 0 1 .026.667Zm3.186 0a.334.334 0 1 1-.026-.667.334.334 0 0 1 .026.667ZM53.56 71.36a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm-1.56 0a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm1.56-1.626a.322.322 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm-1.56 0a.32.32 0 0 1-.333-.32.334.334 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm.746.813a.32.32 0 0 1-.333-.32.334.334 0 0 1 .653 0 .321.321 0 0 1-.32.32Zm1.627 0a.32.32 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm-1.627-1.614a.333.333 0 1 1-.133-.652.333.333 0 0 1 .133.652Zm1.627 0a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm.813 2.427a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm.001-1.626a.321.321 0 0 1-.334-.32.334.334 0 0 1 .654 0 .32.32 0 0 1-.32.32Z" fill="#10B981"/>
      <path d="M53.56 68.12a.334.334 0 0 1-.368-.47.334.334 0 1 1 .368.47Zm-1.56 0a.334.334 0 0 1-.367-.47.333.333 0 1 1 .367.47Zm3.187 0a.335.335 0 0 1-.4-.327.333.333 0 1 1 .4.327Zm-1.627 6.546a.334.334 0 1 1-.026-.667.334.334 0 0 1 .026.667Zm-1.56 0a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm1.56-1.679a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm-1.56 0a.333.333 0 1 1-.027-.665.333.333 0 0 1 .027.665Zm.747.813a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm1.627 0a.334.334 0 1 1-.026-.667.334.334 0 0 1 .026.667Zm-1.628-1.626a.333.333 0 0 1-.333-.32.317.317 0 0 1 .207-.3.32.32 0 0 1 .126-.02.322.322 0 0 1 .32.32.321.321 0 0 1-.32.32Zm1.627 0a.333.333 0 0 1-.333-.32.32.32 0 0 1 .333-.32.32.32 0 0 1 0 .64Zm.813 2.492A.334.334 0 1 1 55.16 74a.334.334 0 0 1 .026.666Zm0-1.679a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666ZM53.56 71.36a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm-1.56 0a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm3.186 0a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm-1.626 6.507a.333.333 0 1 1-.133-.652.333.333 0 0 1 .133.652Zm-1.56 0a.333.333 0 1 1-.133-.652.333.333 0 0 1 .133.652Zm1.56-1.627a.334.334 0 1 1-.026-.668.334.334 0 0 1 .026.668Zm-1.56 0a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm.747.813a.333.333 0 1 1-.027-.665.333.333 0 0 1 .027.665Zm1.627 0a.333.333 0 1 1-.027-.665.333.333 0 0 1 .027.665Zm-1.627-1.627a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm1.627 0a.334.334 0 1 1-.026-.667.334.334 0 0 1 .026.667Zm.813 2.441a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm-.001-1.627a.334.334 0 1 1-.026-.667.334.334 0 0 1 .026.667Z" fill="#10B981"/>
      <path d="M53.56 74.666a.334.334 0 1 1-.026-.667.334.334 0 0 1 .026.667Zm-1.56 0a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm3.186 0A.334.334 0 1 1 55.16 74a.334.334 0 0 1 .026.666Zm.001-6.546a.335.335 0 0 1-.4-.327.333.333 0 1 1 .4.327Zm0-1.627a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm-.001-1.626a.334.334 0 1 1-.026-.667.334.334 0 0 1 .026.667Zm0 6.493a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm.001-1.626a.321.321 0 0 1-.334-.32.334.334 0 0 1 .654 0 .32.32 0 0 1-.32.32Z" fill="#10B981"/>
      <path d="M55.187 68.12a.335.335 0 0 1-.4-.327.333.333 0 1 1 .4.327Zm-.001 6.546A.334.334 0 1 1 55.16 74a.334.334 0 0 1 .026.666Zm0-1.679a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm0-1.627a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm.001 6.507a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm-.001-1.627a.334.334 0 1 1-.026-.667.334.334 0 0 1 .026.667Z" fill="#10B981"/>
      <path d="M55.186 74.666A.334.334 0 1 1 55.16 74a.334.334 0 0 1 .026.666ZM51.2 78.667a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm1.627 0a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm1.626-.001a.334.334 0 1 1 .32-.32.32.32 0 0 1-.32.32Z" fill="#10B981"/>
      <path d="M52.827 78.667a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm6.506-18.481a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653Zm0 1.628a.32.32 0 1 1-.026.639.32.32 0 0 1 .026-.64Zm.814-.814a.32.32 0 0 1 0 .64.32.32 0 0 1-.334-.32.333.333 0 0 1 .334-.32Zm-1.627 0a.32.32 0 0 1 0 .64.32.32 0 0 1-.334-.32.334.334 0 0 1 .334-.32Zm1.627 1.666a.32.32 0 1 1-.027.64.32.32 0 0 1 .027-.64Zm-1.627 0a.32.32 0 1 1-.027.64.32.32 0 0 1 .027-.64Zm-.814-2.48a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653Zm0 1.628a.32.32 0 1 1-.026.64.32.32 0 0 1 .026-.64Zm1.627 1.626a.32.32 0 0 1 .32.32.333.333 0 0 1-.653 0 .32.32 0 0 1 .333-.32Zm-1.627 0a.32.32 0 0 1 .32.32.334.334 0 0 1-.653 0 .32.32 0 0 1 .333-.32Zm1.627-6.507a.333.333 0 1 1-.132.654.333.333 0 0 1 .132-.654Zm0 1.627a.333.333 0 1 1-.132.654.333.333 0 0 1 .132-.654Zm.814-.813a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-1.627 0a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm1.627 1.626a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-1.627 0a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm-.814-2.44a.334.334 0 1 1-.132.654.334.334 0 0 1 .132-.654Zm0 1.627a.334.334 0 1 1-.132.654.334.334 0 0 1 .132-.654Z" fill="#10B981"/>
      <path d="M59.333 60.186a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653Zm-1.627 0a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653Zm1.627-6.506a.333.333 0 1 1 0 .665.333.333 0 0 1 0-.665Zm0 1.627a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Zm.814-.814a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm-1.627 0a.333.333 0 1 1 0 .667.333.333 0 0 1 0-.667Zm1.627 1.627a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm-1.627 0a.333.333 0 1 1-.133.652.333.333 0 0 1 .133-.652Zm-.814-2.44a.333.333 0 1 1 0 .665.333.333 0 0 1 0-.665Zm0 1.627a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm1.627 1.626a.333.333 0 1 1-.132.654.333.333 0 0 1 .132-.654Zm-1.627 0a.334.334 0 1 1-.132.654.334.334 0 0 1 .132-.654Zm1.627-6.493a.32.32 0 0 1 .32.32.333.333 0 0 1-.653 0 .32.32 0 0 1 .333-.32Zm0 1.56a.333.333 0 1 1 0 .667.333.333 0 0 1 0-.667Zm.814-.747a.32.32 0 0 1 .32.32.334.334 0 0 1-.654 0 .319.319 0 0 1 .334-.32Zm-1.627 0a.32.32 0 0 1 .32.32.333.333 0 0 1-.654 0 .32.32 0 0 1 .334-.32Zm1.627 1.613a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm-1.627 0a.333.333 0 1 1 0 .667.333.333 0 0 1 0-.667Zm-.814-2.426a.32.32 0 0 1 .32.32.334.334 0 0 1-.653 0 .32.32 0 0 1 .333-.32Zm0 1.56a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Z" fill="#10B981"/>
      <path d="M59.333 53.68a.333.333 0 1 1 0 .665.333.333 0 0 1 0-.665Zm-1.627 0a.333.333 0 1 1 0 .665.333.333 0 0 1 0-.665Zm-1.626 6.507a.334.334 0 0 1 0 .653.32.32 0 0 1-.32-.334.32.32 0 0 1 .32-.32Zm1.626-.001a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653Zm-1.626 1.627a.32.32 0 1 1 0 .64.32.32 0 0 1 0-.64Zm1.626.001a.32.32 0 1 1-.026.64.32.32 0 0 1 .026-.64ZM56.893 61a.32.32 0 0 1 0 .64.32.32 0 0 1-.333-.32.333.333 0 0 1 .333-.32Zm0 1.666a.32.32 0 1 1-.027.64.32.32 0 0 1 .027-.64Zm-.813.774a.32.32 0 1 1-.32.32.307.307 0 0 1 .32-.32Zm1.626 0a.32.32 0 0 1 .32.32.334.334 0 0 1-.653 0 .32.32 0 0 1 .333-.32Zm-1.626-6.506a.334.334 0 0 1 0 .653.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32Zm1.626-.001a.334.334 0 1 1-.132.654.334.334 0 0 1 .132-.654ZM56.08 58.56a.334.334 0 0 1 0 .653.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32Zm1.626 0a.334.334 0 1 1-.132.654.334.334 0 0 1 .132-.654Zm-.812-.813a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm0 1.626a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Z" fill="#10B981"/>
      <path d="M56.08 60.187a.334.334 0 0 1 0 .653.32.32 0 0 1-.32-.334.32.32 0 0 1 .32-.32Zm1.626-.001a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653ZM56.08 53.68a.334.334 0 0 1 0 .653.321.321 0 0 1-.32-.32.32.32 0 0 1 .32-.333Zm1.626 0a.333.333 0 1 1 0 .665.333.333 0 0 1 0-.665Zm-1.626 1.627a.333.333 0 0 1 0 .653.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.333Zm1.626 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm-.813-.814a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm.001 1.627a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm-.814.814a.334.334 0 0 1 0 .653.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32Zm1.626-.001a.334.334 0 1 1-.132.654.334.334 0 0 1 .132-.654ZM56.08 50.44a.32.32 0 1 1-.32.32.307.307 0 0 1 .32-.32Zm1.626 0a.32.32 0 0 1 .32.32.334.334 0 0 1-.653 0 .32.32 0 0 1 .333-.32ZM56.08 52a.334.334 0 0 1 0 .653.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.333Zm1.626 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm-.813-.747a.32.32 0 0 1 .32.32.334.334 0 0 1-.653 0 .319.319 0 0 1 .333-.32Zm0 1.613a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Z" fill="#10B981"/>
      <path d="M56.08 53.68a.334.334 0 0 1 0 .653.321.321 0 0 1-.32-.32.32.32 0 0 1 .32-.333Zm1.626 0a.333.333 0 1 1 0 .665.333.333 0 0 1 0-.665ZM60 49.6a.333.333 0 1 1 .133.654A.333.333 0 0 1 60 49.6Zm-1.573 0a.333.333 0 1 1 .133.653.333.333 0 0 1-.133-.653Z" fill="#10B981"/>
      <path d="M60 49.6a.333.333 0 1 1 .133.654A.333.333 0 0 1 60 49.6Zm-3.186 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Z" fill="#10B981"/>
      <path d="M56.814 49.6a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667ZM56 67.307a.333.333 0 1 1 0-.64.333.333 0 0 1 0 .653v-.013Zm0-1.627a.331.331 0 0 1-.367-.184.334.334 0 0 1 .367-.47.334.334 0 0 1 0 .654Zm.814 2.44a.334.334 0 0 1-.368-.47.334.334 0 1 1 .368.47Zm0-1.627a.333.333 0 1 1-.133-.652.333.333 0 0 1 .133.652Zm-.001-1.626a.333.333 0 1 1-.026-.667.333.333 0 0 1 .026.667ZM56 70.547a.32.32 0 0 1-.333-.32.334.334 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm0-1.614a.333.333 0 1 1-.133-.652.333.333 0 0 1 .133.652Zm.813 2.427a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm.001-1.626a.322.322 0 0 1-.334-.32.334.334 0 0 1 .654 0 .32.32 0 0 1-.32.32Z" fill="#10B981"/>
      <path d="M56.814 68.12a.334.334 0 0 1-.368-.47.334.334 0 1 1 .368.47ZM56 73.8a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm0-1.626a.334.334 0 0 1-.333-.32.32.32 0 0 1 .333-.32.32.32 0 1 1 0 .64Zm.813 2.492a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm0-1.679a.333.333 0 1 1-.026-.665.333.333 0 0 1 .026.665Zm0-1.627a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32ZM56 77.053a.333.333 0 1 1-.027-.665.333.333 0 0 1 .027.665Zm0-1.627a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm.814 2.441a.333.333 0 1 1-.133-.652.333.333 0 0 1 .133.652Zm-.001-1.627a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Z" fill="#10B981"/>
      <path d="M56.813 74.666a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm1.614-6.546a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm-1.613 0a.334.334 0 0 1-.368-.47.334.334 0 1 1 .368.47Zm1.613-1.626a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm-1.613-.001a.333.333 0 1 1-.133-.652.333.333 0 0 1 .133.652Zm.8.813a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665Zm1.626 0a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665Zm-1.626-1.626a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm1.626 0a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm.76 2.44a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm0-1.626a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm-1.573-1.627a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.614 0a.333.333 0 1 1-.026-.667.333.333 0 0 1 .026.667Zm3.187 0a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Zm-1.573 6.493a.32.32 0 1 1 .026-.639.32.32 0 0 1-.026.64Zm-1.614 0a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm1.613-1.626a.32.32 0 0 1-.32-.32.333.333 0 0 1 .654 0 .32.32 0 0 1-.334.32Zm-1.612 0a.322.322 0 0 1-.334-.32.334.334 0 0 1 .654 0 .32.32 0 0 1-.32.32Zm.799.813a.32.32 0 0 1-.32-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.333.32Zm1.627 0a.32.32 0 0 1-.32-.32.334.334 0 0 1 .653 0 .32.32 0 0 1-.333.32Zm-1.626-1.614a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm1.626 0a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667ZM60 71.36a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Zm0-1.626a.32.32 0 0 1-.32-.32.334.334 0 0 1 .653 0 .319.319 0 0 1-.333.32Z" fill="#10B981"/>
      <path d="M58.427 68.12a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm-1.613 0a.334.334 0 0 1-.368-.47.334.334 0 1 1 .368.47Zm3.186 0a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm-1.573 6.547a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.614-.001a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm1.614-1.679a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.614 0a.333.333 0 1 1-.026-.665.333.333 0 0 1 .026.665Zm.801.813a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm1.626 0a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.627-1.626a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.333.333 0 0 1-.333.32Zm1.627 0a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.333.333 0 0 1-.333.32Zm.76 2.493a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Zm0-1.68a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Zm-1.573-1.627a.32.32 0 1 1 .026-.639.32.32 0 0 1-.026.64Zm-1.614 0a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm3.187 0a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Zm-1.573 6.507a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm-1.613 0a.333.333 0 1 1-.133-.652.333.333 0 0 1 .133.652Zm1.613-1.627a.334.334 0 1 1 .133-.654.334.334 0 0 1-.133.654Zm-1.614 0a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm.801.814a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm1.626 0a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.626-1.627a.334.334 0 1 1 .132-.654.334.334 0 0 1-.132.654Zm1.626 0a.334.334 0 1 1 .132-.654.334.334 0 0 1-.132.654Zm.76.813a.334.334 0 1 1 .133-.654.334.334 0 0 1-.133.654Z" fill="#10B981"/>
      <path d="M58.427 74.667a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.614-.001a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm3.187.001a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Zm0-6.547a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm0-1.626a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm0-1.627a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Zm0 6.493a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Zm0-1.626a.32.32 0 0 1-.32-.32.334.334 0 0 1 .653 0 .319.319 0 0 1-.333.32Z" fill="#10B981"/>
      <path d="M60 68.12a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm0 6.547a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Zm0-1.68a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Zm0-1.627a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Zm0 4.88a.334.334 0 1 1 .133-.654.334.334 0 0 1-.133.654Z" fill="#10B981"/>
      <path d="M60 74.667a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Zm.946-14.48a.334.334 0 1 1 .026.667.334.334 0 0 1-.026-.667Zm.001 1.626a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32ZM61.76 61a.333.333 0 0 1 .334.32.32.32 0 0 1-.334.32.32.32 0 0 1 0-.64Zm0 1.667a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32Zm-.813.773a.32.32 0 0 1 .333.32.334.334 0 0 1-.653 0 .32.32 0 0 1 .32-.32Zm-.001-6.507a.333.333 0 1 1 .027.666.333.333 0 0 1-.027-.666Zm0 1.627a.334.334 0 1 1 .026.667.334.334 0 0 1-.026-.667Zm.814-.814a.332.332 0 0 1 .075.661.333.333 0 1 1-.075-.66Zm0 1.627a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Z" fill="#10B981"/>
      <path d="M60.946 60.187a.334.334 0 1 1 .026.667.334.334 0 0 1-.026-.667Zm.001-6.507a.333.333 0 1 1 .132.652.333.333 0 0 1-.132-.653Zm0 1.626a.331.331 0 0 1 .367.184.333.333 0 1 1-.367-.184Zm.813-.813a.333.333 0 1 1 .133.652.333.333 0 0 1-.133-.652Zm0 1.627a.333.333 0 1 1 .026.665.333.333 0 0 1-.026-.665Zm-.814.813a.333.333 0 1 1 .027.666.333.333 0 0 1-.027-.666Zm.001-6.493a.32.32 0 0 1 .333.32.334.334 0 0 1-.653 0 .32.32 0 0 1 .32-.32Zm0 1.56a.334.334 0 0 1 .367.47.334.334 0 1 1-.367-.47Zm.813-.747a.321.321 0 0 1 .334.32.333.333 0 0 1-.654 0 .32.32 0 0 1 .32-.32Zm0 1.614a.333.333 0 1 1 .133.652.333.333 0 0 1-.133-.652Z" fill="#10B981"/>
      <path d="M60.947 53.68a.333.333 0 1 1 .132.652.333.333 0 0 1-.132-.653Zm.733-4.08a.333.333 0 1 1 .027.665.333.333 0 0 1-.027-.665Z" fill="#10B981"/>
      <path d="M61.68 49.6a.333.333 0 1 1 .027.665.333.333 0 0 1-.027-.665Zm-.813 17.707a.333.333 0 0 1 0-.654.32.32 0 0 1 .32.32.32.32 0 0 1-.32.334Zm0-1.627a.333.333 0 0 1 0-.654.32.32 0 0 1 .32.32.32.32 0 0 1-.32.334Zm.813 2.44a.333.333 0 1 1-.027-.665.333.333 0 0 1 .027.665Zm0-1.627a.333.333 0 1 1-.027-.666.333.333 0 0 1 .027.666Zm0-1.626a.333.333 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm-.813 5.679a.32.32 0 1 1 .32-.32.307.307 0 0 1-.32.32Zm0-1.612a.333.333 0 0 1 0-.654.32.32 0 0 1 .32.32.32.32 0 0 1-.32.334Zm.813 2.426a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm0-1.626a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Z" fill="#10B981"/>
      <path d="M61.68 68.12a.333.333 0 1 1-.027-.665.333.333 0 0 1 .027.665Zm-.813 5.68a.333.333 0 0 1 0-.653.317.317 0 0 1 .3.206.32.32 0 0 1 .02.127.32.32 0 0 1-.32.32Zm0-1.626a.32.32 0 1 1 0-.64.306.306 0 0 1 .32.32.32.32 0 0 1-.32.32Zm.813 2.493a.334.334 0 0 1 0-.653.319.319 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0-1.68a.333.333 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm0-1.627a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm-.813 5.694a.333.333 0 0 1 0-.654.32.32 0 0 1 .32.334.32.32 0 0 1-.32.32Zm0-1.627a.333.333 0 0 1 0-.654.32.32 0 0 1 .32.334.32.32 0 0 1-.32.32Zm.813.813a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Z" fill="#10B981"/>
      <path d="M61.68 74.667a.334.334 0 0 1 0-.653.319.319 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0-6.547a.334.334 0 0 1 0-.653.32.32 0 0 1 .32.32.322.322 0 0 1-.32.333Zm0-1.627a.333.333 0 1 1 .32-.333.322.322 0 0 1-.32.334Zm0-1.626a.333.333 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm0 6.493a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm0-1.626a.32.32 0 1 1 .32-.32.308.308 0 0 1-.32.32Z" fill="#10B981"/>
      <path d="M61.68 68.12a.334.334 0 0 1 0-.653.32.32 0 0 1 .32.32.322.322 0 0 1-.32.333Zm0 6.547a.334.334 0 0 1 0-.653.319.319 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0-1.681a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0-1.626a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm0 4.88a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Z" fill="#10B981"/>
      <path d="M61.68 74.667a.334.334 0 0 1 0-.653.319.319 0 0 1 .32.333.32.32 0 0 1-.32.32ZM45.333 40.493a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm1.641 0a.334.334 0 1 1-.027-.667.334.334 0 0 1 .027.667Zm-1.64 3.241a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm-.001-1.614a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm.827.8a.32.32 0 1 1 .32-.32.305.305 0 0 1-.195.3.307.307 0 0 1-.125.02Zm0-1.587a.334.334 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm.813 2.401a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm.001-1.614a.333.333 0 1 1-.027-.666.333.333 0 0 1 .027.666Z" fill="#10B981"/>
      <path d="M45.333 40.493a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm1.641 0a.334.334 0 1 1-.027-.667.334.334 0 0 1 .027.667Zm-1.641 6.493a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm.001-1.652a.32.32 0 0 1 0-.64.305.305 0 0 1 .3.195c.015.04.022.082.02.125a.32.32 0 0 1-.32.32Zm.826.839a.333.333 0 0 1 0-.654.32.32 0 0 1 .32.334.32.32 0 0 1-.32.32Zm0-1.627a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm.813 2.441a.333.333 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm0-1.654a.32.32 0 1 1 0-.638.32.32 0 0 1 0 .638Zm-1.639-1.599a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm1.639 0a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Z" fill="#10B981"/>
      <path d="M46.974 40.493a.334.334 0 1 1-.027-.667.334.334 0 0 1 .027.667Zm1.626 3.241a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm-1.627 0a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64ZM48.6 42.12a.335.335 0 0 1-.4-.327.334.334 0 1 1 .4.327Zm-1.626 0a.333.333 0 1 1-.027-.666.333.333 0 0 1 .027.666Zm.813.8a.307.307 0 0 1-.32-.253.32.32 0 1 1 .64 0 .32.32 0 0 1-.32.253Zm1.626 0a.32.32 0 0 1-.333-.32.334.334 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm-1.626-1.587a.319.319 0 0 1-.32-.333.32.32 0 0 1 .32-.32.333.333 0 0 1 0 .653Zm1.626 0a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm.813 2.401a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm.001-1.614a.334.334 0 0 1-.368-.47.334.334 0 1 1 .368.47Z" fill="#10B981"/>
      <path d="M46.974 40.493a.334.334 0 1 1-.027-.667.334.334 0 0 1 .027.667Zm1.626 6.494a.333.333 0 1 1-.026-.665.333.333 0 0 1 .026.665Zm-1.627 0a.333.333 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm1.627-1.653a.334.334 0 0 1-.333-.32.32.32 0 0 1 .333-.32.32.32 0 0 1 0 .64Zm-1.627-.001a.32.32 0 1 1 0-.638.32.32 0 0 1 0 .638Zm.814.84a.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.334.333.333 0 0 1 0 .654Zm1.627 0a.333.333 0 1 1-.027-.666.333.333 0 0 1 .027.666Zm-1.627-1.627a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm1.627 0a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm.812 2.441a.333.333 0 1 1-.026-.665.333.333 0 0 1 .026.665Zm.001-1.653a.333.333 0 0 1-.333-.32.32.32 0 0 1 .333-.32.32.32 0 0 1 0 .64Zm-1.627-1.6a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm-1.627 0a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm3.253 0a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Z" fill="#10B981"/>
      <path d="M50.226 43.734a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm.001-1.614a.334.334 0 0 1-.368-.47.334.334 0 1 1 .368.47Zm-.001 4.867a.333.333 0 1 1-.026-.665.333.333 0 0 1 .026.665Zm.001-1.653a.333.333 0 0 1-.333-.32.32.32 0 0 1 .333-.32.32.32 0 0 1 0 .64Z" fill="#10B981"/>
      <path d="M50.226 43.734a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32ZM46.24 49.44a.334.334 0 1 1 .132-.653.334.334 0 0 1-.132.653Zm-.814-.813a.32.32 0 0 1 0-.64.321.321 0 0 1 .334.32.333.333 0 0 1-.334.32Zm1.627 0a.32.32 0 1 1 0-.64.322.322 0 0 1 .334.32.333.333 0 0 1-.334.32Zm.814.813a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.627-1.627a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.334.334 0 0 1-.333.32Zm1.627 0a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.333.333 0 0 1-.333.32Zm1.627 1.627a.334.334 0 1 1 .132-.653.334.334 0 0 1-.132.653Z" fill="#10B981"/>
      <path d="M47.867 49.44a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm.813-.813a.32.32 0 0 1 0-.64.321.321 0 0 1 .334.32.334.334 0 0 1-.334.32Zm1.626 0a.32.32 0 1 1 0-.64.307.307 0 0 1 .32.32.32.32 0 0 1-.32.32Zm-.813-.814a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.334.334 0 0 1-.333.32Zm-1.626 0a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.333.333 0 0 1-.333.32Zm3.173-4.893a.32.32 0 0 1-.373-.254.333.333 0 0 1 .653 0 .32.32 0 0 1-.28.254Zm0-1.587a.333.333 0 1 1-.133-.654.333.333 0 0 1 .133.654Zm.813 2.401a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm.001-1.614a.335.335 0 0 1-.4-.327.334.334 0 1 1 .4.327Zm-.814 4.053a.334.334 0 1 1-.027-.667.334.334 0 0 1 .027.667Zm0-1.627a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm.813 2.441a.333.333 0 1 1-.026-.665.333.333 0 0 1 .026.665Zm0-1.653a.333.333 0 0 1-.334-.32.32.32 0 0 1 .334-.32.32.32 0 0 1 0 .64Z" fill="#10B981"/>
      <path d="M51.853 43.734a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm1.614-.001a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Z" fill="#10B981"/>
      <path d="M51.853 43.734a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm1.614-1.614a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm-1.613 0a.335.335 0 0 1-.4-.327.334.334 0 1 1 .4.327Zm.799.8a.32.32 0 0 1-.32-.32.334.334 0 0 1 .653 0 .319.319 0 0 1-.333.32Zm1.627 0a.32.32 0 0 1-.32-.32.355.355 0 1 1 .707.067.32.32 0 0 1-.387.253Zm.814.813a.32.32 0 1 1 .026-.638.32.32 0 0 1-.026.639Zm-1.628 3.254a.333.333 0 1 1 .133-.652.333.333 0 0 1-.133.652Zm-1.613 0a.333.333 0 1 1-.026-.665.333.333 0 0 1 .026.665Zm1.614-1.653a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.333.333 0 0 1-.333.32Zm-1.614 0a.333.333 0 0 1-.334-.32.32.32 0 0 1 .334-.32.32.32 0 0 1 0 .64Zm.801.84a.333.333 0 1 1 .132-.654.333.333 0 0 1-.132.654Zm1.626 0a.334.334 0 1 1 .132-.654.334.334 0 0 1-.132.654Zm-1.627-1.627a.321.321 0 0 1-.298-.39.32.32 0 1 1 .298.39Zm1.627 0a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Zm.813 2.44a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Zm0-1.653a.32.32 0 1 1 0-.64.32.32 0 0 1 .334.32.334.334 0 0 1-.334.32Zm-1.626-1.601a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Z" fill="#10B981"/>
      <path d="M51.853 43.734a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm3.241-.001a.32.32 0 1 1 .026-.638.32.32 0 0 1-.026.639Z" fill="#10B981"/>
      <path d="M55.094 43.733a.32.32 0 1 1 .026-.638.32.32 0 0 1-.026.639Zm-.001 3.254a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Zm0-1.653a.32.32 0 1 1 0-.64.32.32 0 0 1 .334.32.334.334 0 0 1-.334.32Z" fill="#10B981"/>
      <path d="M55.094 43.733a.32.32 0 1 1 .026-.638.32.32 0 0 1-.026.639ZM51.12 49.44a.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.333.333.333 0 0 1 0 .653Zm.88-.813a.334.334 0 0 1-.333-.32A.319.319 0 0 1 52 48a.322.322 0 0 1 .32.32.32.32 0 0 1-.32.307Zm.747.813a.333.333 0 1 1-.027-.666.333.333 0 0 1 .027.666Zm-1.627-1.627a.32.32 0 0 1-.32-.32.307.307 0 0 1 .32-.32.32.32 0 1 1 0 .64Zm1.626 0a.333.333 0 0 1-.333-.32.317.317 0 0 1 .207-.3.32.32 0 0 1 .126-.02.32.32 0 0 1 0 .64Zm1.628 1.627a.334.334 0 1 1-.027-.667.334.334 0 0 1 .027.667Z" fill="#10B981"/>
      <path d="M52.747 49.44a.333.333 0 1 1-.027-.666.333.333 0 0 1 .027.666Zm.813-.813a.333.333 0 0 1-.333-.32.319.319 0 0 1 .333-.32.32.32 0 0 1 0 .64Zm1.627 0a.333.333 0 0 1-.334-.32.32.32 0 0 1 .334-.32.32.32 0 1 1 0 .64Zm-.814-.814a.333.333 0 0 1-.333-.32.32.32 0 0 1 .333-.32.32.32 0 0 1 0 .64Zm-1.627 0a.333.333 0 0 1-.333-.32.317.317 0 0 1 .207-.3.32.32 0 0 1 .126-.02.32.32 0 0 1 0 .64Zm3.161-4.893a.32.32 0 0 1-.32-.32.334.334 0 0 1 .653 0 .319.319 0 0 1-.333.32Zm.813.813a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Zm-.813 2.441a.334.334 0 1 1 .132-.654.334.334 0 0 1-.132.654Zm-.001-1.627a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Zm.814 2.44a.333.333 0 1 1 .133-.652.333.333 0 0 1-.133.652Zm0-1.653a.32.32 0 1 1 0-.64.32.32 0 0 1 .334.32.333.333 0 0 1-.334.32Z" fill="#10B981"/>
      <path d="M56.72 43.733a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Zm1.626.001a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Z" fill="#10B981"/>
      <path d="M56.72 43.733a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Zm3.28.001a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm-1.654 3.253a.333.333 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm-1.626 0a.333.333 0 1 1 .133-.652.333.333 0 0 1-.133.652Zm1.626-1.654a.32.32 0 1 1 0-.639.32.32 0 0 1 0 .64Zm-1.626.001a.32.32 0 1 1 0-.64.32.32 0 0 1 .334.32.333.333 0 0 1-.334.32Zm.814.839a.334.334 0 0 1 0-.654.32.32 0 0 1 .32.334.32.32 0 0 1-.32.32Zm1.626 0a.333.333 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm-1.627-1.627a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm1.627 0a.32.32 0 1 1 0-.639.32.32 0 0 1 0 .64Zm.84 2.44a.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.333.333.333 0 0 1 0 .653Zm0-1.652a.32.32 0 0 1-.32-.32.307.307 0 0 1 .32-.32.32.32 0 1 1 0 .64Zm-1.654-1.6a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Z" fill="#10B981"/>
      <path d="M56.72 43.733a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Zm3.28.001a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Z" fill="#10B981"/>
      <path d="M60 43.734a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm0 3.252a.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.333.333.333 0 0 1 0 .653Zm0-1.652a.32.32 0 0 1-.32-.32.307.307 0 0 1 .32-.32.32.32 0 1 1 0 .64Z" fill="#10B981"/>
      <path d="M60 43.734a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm-4 5.706a.333.333 0 1 1-.027-.666.333.333 0 0 1 .027.666Zm.814-.813a.333.333 0 0 1-.334-.32.319.319 0 0 1 .334-.32.32.32 0 0 1 0 .64Zm.8.813a.334.334 0 1 1 .132-.653.334.334 0 0 1-.132.653ZM56 47.813a.334.334 0 0 1-.333-.32.32.32 0 0 1 .333-.32.32.32 0 1 1 0 .64Zm1.613 0a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.333.333 0 0 1-.333.32Zm1.627 1.627a.334.334 0 1 1 .132-.653.334.334 0 0 1-.132.653Z" fill="#10B981"/>
      <path d="M57.614 49.44a.334.334 0 1 1 .132-.653.334.334 0 0 1-.132.653Zm.812-.813a.32.32 0 0 1 0-.64.321.321 0 0 1 .334.32.333.333 0 0 1-.334.32Zm1.574 0a.32.32 0 0 1-.32-.32A.32.32 0 0 1 60 48a.32.32 0 0 1 .333.32.333.333 0 0 1-.333.307Zm-.76-.814a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.334.334 0 0 1-.333.32Zm-1.627 0a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.333.333 0 0 1-.333.32Zm3.174-1.64a.333.333 0 1 1-.027-.666.333.333 0 0 1 .027.666Zm0-1.627a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm.813 2.441a.333.333 0 1 1-.026-.665.333.333 0 0 1 .026.665Zm0-1.653a.334.334 0 0 1-.333-.32.32.32 0 0 1 .333-.32.32.32 0 0 1 0 .64Z" fill="#10B981"/>
      <path d="M61.6 46.987a.333.333 0 1 1-.026-.665.333.333 0 0 1 .026.665Zm0-1.653a.334.334 0 0 1-.333-.32.32.32 0 0 1 .333-.32.32.32 0 0 1 0 .64Zm-.733 4.106a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm.813-.813a.32.32 0 0 1 0-.64.307.307 0 0 1 .32.32.32.32 0 0 1-.32.32Zm-.813-.814a.32.32 0 0 1 0-.64.305.305 0 0 1 .3.195c.015.04.022.083.02.125a.32.32 0 0 1-.32.32Zm4.973 12.373a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653Zm0 1.628a.32.32 0 1 1-.026.64.32.32 0 0 1 .026-.64Zm.826-.814a.32.32 0 1 1 0 .64.32.32 0 0 1-.333-.32.334.334 0 0 1 .333-.32Zm-1.64 0a.319.319 0 0 1 .307.334.32.32 0 0 1-.32.32.32.32 0 0 1-.307-.451.332.332 0 0 1 .32-.203Zm1.64 1.666a.32.32 0 1 1-.026.64.32.32 0 0 1 .026-.64Zm-1.639 0a.32.32 0 1 1-.027.64.32.32 0 0 1 .027-.64Zm-.814-2.48a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653Zm0 1.628a.32.32 0 1 1-.026.639.32.32 0 0 1 .026-.64Zm1.627 1.626a.32.32 0 0 1 .32.32.334.334 0 0 1-.653 0 .32.32 0 0 1 .333-.32Zm-1.627 0a.32.32 0 0 1 .32.32.334.334 0 0 1-.653 0 .317.317 0 0 1 .207-.3.32.32 0 0 1 .126-.02Zm1.627-6.507a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm0 1.627a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm.826-.813a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653Zm-1.639 0a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm1.639 1.626a.333.333 0 0 1 0 .627.333.333 0 1 1 0-.654v.027Zm-1.639 0a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-.814-2.44a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm0 1.627a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Z" fill="#10B981"/>
      <path d="M65.84 60.186a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653Zm-1.627 0a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653Zm1.627-6.506a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Zm0 1.627a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm.826-.814a.333.333 0 1 1 0 .667.333.333 0 0 1 0-.667Zm-1.639 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm1.639 1.627a.333.333 0 1 1-.132.652.333.333 0 0 1 .132-.652Zm-1.639 0a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm-.814-2.44a.333.333 0 1 1 0 .665.333.333 0 0 1 0-.665Zm0 1.627a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm1.627 1.626a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-1.627 0a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm1.627-6.493a.32.32 0 0 1 .32.32.334.334 0 0 1-.653 0 .32.32 0 0 1 .333-.32Zm0 1.56a.334.334 0 1 1 0 .668.334.334 0 0 1 0-.668Zm.826-.747a.32.32 0 0 1 .32.32.333.333 0 0 1-.653 0 .32.32 0 0 1 .333-.32Zm-1.639 0a.32.32 0 0 1 .32.32.334.334 0 0 1-.654 0 .319.319 0 0 1 .334-.32Zm1.639 1.613a.333.333 0 1 1 0 .667.333.333 0 0 1 0-.667Zm-1.639 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm-.814-2.426a.32.32 0 0 1 .32.32.334.334 0 0 1-.653 0 .317.317 0 0 1 .207-.3.32.32 0 0 1 .126-.02Zm0 1.56a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Z" fill="#10B981"/>
      <path d="M65.84 53.68a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Zm-1.627 0a.333.333 0 1 1 0 .665.333.333 0 0 1 0-.665Zm-1.626 6.507a.333.333 0 0 1 0 .653.32.32 0 0 1-.32-.334.32.32 0 0 1 .32-.32Zm1.626-.001a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653Zm-1.626 1.627a.322.322 0 0 1 .295.198.32.32 0 1 1-.295-.198Zm1.626.001a.32.32 0 1 1-.026.639.32.32 0 0 1 .026-.64ZM63.4 61a.32.32 0 1 1 0 .64.319.319 0 0 1-.334-.307A.333.333 0 0 1 63.4 61Zm0 1.666a.32.32 0 1 1-.027.64.32.32 0 0 1 .027-.64Zm-.813.774a.32.32 0 1 1-.32.32.307.307 0 0 1 .32-.32Zm1.626 0a.32.32 0 0 1 .32.32.334.334 0 0 1-.653 0 .317.317 0 0 1 .207-.3.32.32 0 0 1 .126-.02Zm-1.626-6.506a.333.333 0 0 1 0 .653.319.319 0 0 1-.32-.333.32.32 0 0 1 .32-.32Zm1.626-.001a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-1.626 1.627a.333.333 0 0 1 0 .653.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32Zm1.626 0a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-.813-.813a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm0 1.626a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Z" fill="#10B981"/>
      <path d="M62.587 60.187a.333.333 0 0 1 0 .653.32.32 0 0 1-.32-.334.32.32 0 0 1 .32-.32Zm1.626-.001a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653Zm-1.626-6.506a.333.333 0 0 1 0 .653.322.322 0 0 1-.32-.32.32.32 0 0 1 .32-.333Zm1.626 0a.333.333 0 1 1 0 .665.333.333 0 0 1 0-.665Zm-1.626 1.627a.333.333 0 0 1 0 .653.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.333Zm1.626 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm-.813-.814a.333.333 0 1 1 0 .667.333.333 0 0 1 0-.667Zm0 1.627a.333.333 0 1 1-.133.652.333.333 0 0 1 .133-.652Zm-.813.814a.333.333 0 0 1 0 .653.319.319 0 0 1-.32-.333.32.32 0 0 1 .32-.32Zm1.626-.001a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-1.626-6.493a.32.32 0 1 1-.32.32.307.307 0 0 1 .32-.32Zm1.626 0a.32.32 0 0 1 .32.32.334.334 0 0 1-.653 0 .317.317 0 0 1 .207-.3.32.32 0 0 1 .126-.02ZM62.587 52a.333.333 0 0 1 0 .653.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.333Zm1.626 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm-.813-.747a.32.32 0 0 1 .32.32.333.333 0 0 1-.654 0 .32.32 0 0 1 .334-.32Zm0 1.613a.333.333 0 1 1 0 .667.333.333 0 0 1 0-.667Z" fill="#10B981"/>
      <path d="M62.587 53.68a.333.333 0 0 1 0 .653.322.322 0 0 1-.32-.32.32.32 0 0 1 .32-.333Zm1.626 0a.333.333 0 1 1 0 .665.333.333 0 0 1 0-.665Zm2.347-4.08a.333.333 0 1 1 .133.653.333.333 0 0 1-.133-.653Zm-1.627 0a.333.333 0 1 1 .133.654.333.333 0 0 1-.133-.654Z" fill="#10B981"/>
      <path d="M66.56 49.6a.333.333 0 1 1 .133.653.333.333 0 0 1-.133-.653Zm-3.24 0a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Z" fill="#10B981"/>
      <path d="M63.32 49.6a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Zm-.813 17.706a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm0-1.626a.332.332 0 0 1-.4-.327.333.333 0 1 1 .4.327Zm.813 2.44a.335.335 0 0 1-.4-.327.333.333 0 1 1 .4.327Zm0-1.627a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm0-1.626a.334.334 0 1 1-.026-.667.334.334 0 0 1 .026.667Zm-.814 5.68a.32.32 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm.001-1.614a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm.813 2.427a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm0-1.626a.321.321 0 0 1-.334-.32.334.334 0 0 1 .654 0 .32.32 0 0 1-.32.32Z" fill="#10B981"/>
      <path d="M63.32 68.12a.335.335 0 0 1-.4-.327.333.333 0 1 1 .4.327Zm-.813 5.68a.334.334 0 1 1-.026-.667.334.334 0 0 1 .026.667Zm-.001-1.626a.333.333 0 0 1-.333-.32.319.319 0 0 1 .333-.32.32.32 0 0 1 0 .64Zm.814 2.492a.334.334 0 1 1-.026-.666.334.334 0 0 1 .026.666Zm0-1.679a.333.333 0 1 1-.026-.665.333.333 0 0 1 .026.665Zm0-1.627a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm-.813 4.066a.334.334 0 1 1-.026-.667.334.334 0 0 1 .026.667Zm.813.814a.334.334 0 1 1-.026-.667.334.334 0 0 1 .026.667Z" fill="#10B981"/>
      <path d="M63.32 74.666a.334.334 0 1 1-.026-.666.334.334 0 0 1 .026.666Zm1.613-6.546a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm-1.613 0a.335.335 0 0 1-.4-.327.333.333 0 1 1 .4.327Zm1.613-1.626a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665Zm-1.613-.001a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm.8.813a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665Zm1.626 0a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665ZM64.12 65.68a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm1.626 0a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm.814 2.44a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm0-1.626a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm-1.627-1.627a.333.333 0 1 1 .132-.652.333.333 0 0 1-.132.652Zm-1.613 0a.334.334 0 1 1-.026-.667.334.334 0 0 1 .026.667Zm3.24 0a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.626 6.493a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Zm-1.614 0a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm1.613-1.626a.32.32 0 0 1-.32-.32.334.334 0 0 1 .654 0 .317.317 0 0 1-.207.3.32.32 0 0 1-.127.02Zm-1.613 0a.321.321 0 0 1-.334-.32.334.334 0 0 1 .654 0 .32.32 0 0 1-.32.32Zm.8.813a.32.32 0 0 1-.32-.32.334.334 0 0 1 .653 0 .32.32 0 0 1-.333.32Zm1.627 0a.32.32 0 0 1-.32-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.333.32Zm-1.627-1.614a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm1.626 0a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm.814 2.427a.32.32 0 1 1 .026-.639.32.32 0 0 1-.026.64Zm0-1.626a.32.32 0 0 1-.32-.32.333.333 0 0 1 .654 0 .32.32 0 0 1-.334.32Z" fill="#10B981"/>
      <path d="M64.933 68.12a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm-1.613 0a.335.335 0 0 1-.4-.327.333.333 0 1 1 .4.327Zm3.24 0a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm-1.627 6.547a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.613-.001a.334.334 0 1 1-.026-.666.334.334 0 0 1 .026.666Zm1.613-1.679a.333.333 0 1 1 .132-.652.333.333 0 0 1-.132.652Zm-1.613 0a.333.333 0 1 1-.026-.665.333.333 0 0 1 .026.665Zm.8.813a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm1.626 0a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Zm-1.626-1.626a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.333.333 0 0 1-.333.32Zm1.627 0a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.334.334 0 0 1-.333.32Zm.813 2.493a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm0-1.68a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.626-1.627a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Zm-1.614 0a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm3.24 0a.32.32 0 1 1 .026-.639.32.32 0 0 1-.026.64Zm-1.627 4.88a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Zm-1.613 0a.334.334 0 1 1-.026-.667.334.334 0 0 1 .026.667Zm.8-.813a.333.333 0 1 1 .132-.654.333.333 0 0 1-.132.654Zm1.626 0a.334.334 0 1 1 .133-.654.334.334 0 0 1-.133.654Z" fill="#10B981"/>
      <path d="M64.933 74.667a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.613-.001a.334.334 0 1 1-.026-.666.334.334 0 0 1 .026.666Zm3.24.001a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm0-6.547a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm0-1.626a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm0-1.627a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm0 6.493a.32.32 0 1 1 .026-.639.32.32 0 0 1-.026.64Zm0-1.626a.32.32 0 0 1-.32-.32.333.333 0 0 1 .654 0 .32.32 0 0 1-.334.32Z" fill="#10B981"/>
      <path d="M66.56 68.12a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm0 6.547a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm0-1.68a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm0-1.627a.32.32 0 1 1 .026-.639.32.32 0 0 1-.026.64Z" fill="#10B981"/>
      <path d="M66.56 74.667a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm.893-14.48a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm.001 1.626a.32.32 0 1 1-.32.32.334.334 0 0 1 .32-.32Zm.812-.813a.333.333 0 0 1 .334.32.317.317 0 0 1-.207.3.32.32 0 0 1-.127.02.32.32 0 0 1-.267-.307.32.32 0 0 1 .267-.333Zm0 1.667a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32Zm-.812.773a.32.32 0 0 1 .333.32.334.334 0 0 1-.653 0 .321.321 0 0 1 .32-.32Zm-.001-6.507a.333.333 0 1 1 .027.665.333.333 0 0 1-.027-.665Zm0 1.627a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm.813-.814a.333.333 0 1 1 .027.665.333.333 0 0 1-.027-.665Zm0 1.627a.334.334 0 1 1 .027.667.334.334 0 0 1-.027-.667Z" fill="#10B981"/>
      <path d="M67.453 60.187a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm.001-6.507a.333.333 0 1 1 .132.653.333.333 0 0 1-.132-.654Zm0 1.626a.332.332 0 0 1 .4.327.333.333 0 1 1-.4-.327Zm.813-.813a.333.333 0 1 1 .133.653.333.333 0 0 1-.133-.653Zm-.001 1.627a.333.333 0 1 1 .027.666.333.333 0 0 1-.027-.666Zm-.813.813a.333.333 0 1 1 .027.665.333.333 0 0 1-.027-.665Zm.001-6.493a.32.32 0 0 1 .333.32.334.334 0 0 1-.653 0 .32.32 0 0 1 .32-.32Zm0 1.56a.335.335 0 0 1 .4.327.333.333 0 1 1-.4-.327Zm.812-.747a.32.32 0 0 1 .334.32.334.334 0 0 1-.654 0 .32.32 0 0 1 .32-.32Zm.001 1.614a.333.333 0 1 1 .133.653.333.333 0 0 1-.133-.653Z" fill="#10B981"/>
      <path d="M67.454 53.68a.333.333 0 1 1 .132.653.333.333 0 0 1-.132-.654Zm.732-4.08a.333.333 0 1 1 .027.665.333.333 0 0 1-.027-.665Z" fill="#10B981"/>
      <path d="M68.186 49.6a.333.333 0 1 1 .027.665.333.333 0 0 1-.027-.665Zm-.813 17.707a.333.333 0 0 1 0-.654.32.32 0 0 1 .32.32.32.32 0 0 1-.32.334Zm0-1.627a.333.333 0 0 1 0-.654.32.32 0 0 1 .32.32.32.32 0 0 1-.32.334Zm.813 2.44a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm0-1.627a.334.334 0 1 1-.026-.667.334.334 0 0 1 .026.668Zm0-1.626a.334.334 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm-.812 5.679a.32.32 0 1 1 .32-.32.307.307 0 0 1-.32.32Zm-.001-1.612a.333.333 0 0 1 0-.654.32.32 0 0 1 .32.32.32.32 0 0 1-.32.334Zm.813 2.426a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm0-1.626a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Z" fill="#10B981"/>
      <path d="M68.186 68.12a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm-.813 5.68a.333.333 0 0 1 0-.653.318.318 0 0 1 .3.206.32.32 0 0 1 .02.127.32.32 0 0 1-.32.32Zm.001-1.626a.32.32 0 1 1 0-.64.307.307 0 0 1 .32.32.32.32 0 0 1-.32.32Zm.812 2.492a.334.334 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm0-1.679a.333.333 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm0-1.627a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm-.813 4.067a.333.333 0 0 1 0-.654.321.321 0 0 1 .32.334.32.32 0 0 1-.32.32Z" fill="#10B981"/>
      <path d="M68.186 74.666a.334.334 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm0-6.546a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm0-1.627a.334.334 0 1 1-.026-.667.334.334 0 0 1 .026.668Zm0-1.626a.334.334 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm0 6.493a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm0-1.626a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Z" fill="#10B981"/>
      <path d="M68.186 68.12a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm0 6.546a.334.334 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm0-1.679a.333.333 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm0-1.627a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Z" fill="#10B981"/>
      <path d="M68.186 74.666a.334.334 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm-5.773-28.492a.334.334 0 1 1 .133-.654.334.334 0 0 1-.133.654Zm0-1.627a.32.32 0 0 1-.044-.634.32.32 0 1 1 .044.634Zm.814 2.44a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Zm-.001-1.653a.32.32 0 1 1 0-.64.32.32 0 0 1 .334.32.334.334 0 0 1-.334.32Zm1.627 1.653a.333.333 0 1 1 .32-.32.32.32 0 0 1-.32.32Z" fill="#10B981"/>
      <path d="M63.227 46.987a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Zm1.626-1.654a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm-1.627.001a.32.32 0 1 1 0-.64.32.32 0 0 1 .334.32.334.334 0 0 1-.334.32Zm.774.839a.333.333 0 0 1 0-.654.32.32 0 0 1 .32.334.32.32 0 0 1-.32.32Zm1.667 0a.333.333 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm.813.813a.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.333.334.334 0 0 1 0 .653Zm0-1.652a.32.32 0 0 1-.32-.32.307.307 0 0 1 .32-.32.32.32 0 0 1 0 .64Z" fill="#10B981"/>
      <path d="M66.48 46.986a.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.333.334.334 0 0 1 0 .653Zm0-1.652a.32.32 0 0 1-.32-.32.307.307 0 0 1 .32-.32.32.32 0 0 1 0 .64Zm-3.973 4.106a.334.334 0 1 1-.027-.667.334.334 0 0 1 .027.667Zm.813-.813a.333.333 0 0 1-.334-.32.32.32 0 0 1 .334-.32.32.32 0 0 1 0 .64Zm.8.813a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.614-1.627a.333.333 0 0 1-.333-.32.32.32 0 0 1 .333-.32.32.32 0 1 1 0 .64Zm1.614 0a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.333.333 0 0 1-.333.32Zm1.626 1.627a.334.334 0 1 1 .133-.653.334.334 0 0 1-.133.653Z" fill="#10B981"/>
      <path d="M64.12 49.44a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm.813-.813a.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.307.32.32 0 0 1 .334.32.333.333 0 0 1-.334.307Zm1.627 0a.32.32 0 0 1 0-.64.321.321 0 0 1 .334.32.334.334 0 0 1-.334.32Zm-.813-.814a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.334.334 0 0 1-.333.32Zm-1.627 0a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.333.333 0 0 1-.333.32Zm3.174-1.64a.334.334 0 1 1-.027-.667.334.334 0 0 1 .027.667Zm.812.814a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Z" fill="#10B981"/>
      <path d="M68.106 46.987a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm-.733 2.453a.333.333 0 0 1 0-.653.321.321 0 0 1 .32.333.32.32 0 0 1-.32.32Zm.813-.814a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm-.812-.813a.32.32 0 0 1 0-.64.305.305 0 0 1 .32.32.32.32 0 0 1-.32.32Z" fill="#10B981"/>
    </svg>
  )
}
