import React, { FC, useEffect, useRef, useState } from 'react'
import Translation from "../../helper/Translation";
import { Country } from "@shobbak/react-services/dist/Entities";
import CountryCode from "../../helper/countryCode";
import { phone } from "phone";

interface AuthEmailInputProps {
  error: string;
  countryCodes: Country[]
  defaultSelectedCountry: Country
  onSuccessValidation?: (validMobileNumber)=>void
  isValid?: (valid: boolean)=> void
  onChange?: () => void
}

const AuthMobileInput: FC<AuthEmailInputProps> = ({error,countryCodes,isValid,onSuccessValidation,defaultSelectedCountry, onChange,...props})=> {

  const [selectedCountry , setSelectCountryCode ] = useState<number>(undefined)
  const [mobileNumber , setSelectMobileNumber ] = useState<number>(undefined)
  const [fullMobileNumber , setFullMobileNumber ] = useState<string>('')
  const [errorBag , setErrorBag] = useState<string>(error)
  const componentRenderedBefore = useRef<boolean>(false)

  const validateMobileNumber = (countryId , mobileNumber)=> {
    if (!countryId)return ;
    if(!componentRenderedBefore.current){
     return  componentRenderedBefore.current = true
    }

    let country = countryCodes.find(country=> country.id == countryId)
    const prefix =CountryCode.getDisplayCodeByCode3(country.code3)

    const mobile = `+${prefix}${mobileNumber}`
    const isMobileValid  =  phone(mobile).isValid
    isValid && isValid(isMobileValid)
    setFullMobileNumber(`${prefix}${mobileNumber}`)
    if (isMobileValid){
      setErrorBag(undefined)
      onSuccessValidation && onSuccessValidation(mobile)
    }else{
      setErrorBag(Translation.t('errors.messages.invalid_mobile_number'))
    }



  }

  useEffect(()=> setSelectCountryCode(parseInt(defaultSelectedCountry.id)), [defaultSelectedCountry])
  useEffect(()=> {validateMobileNumber(selectedCountry , mobileNumber)}, [selectedCountry , mobileNumber])

 return (
     <div>
       <label className="block text-gray-700 text-start text-sm leading-5 font-normal mt-1" htmlFor="mobile">{Translation.t('inputs.phone_number.label')}</label>

       <div
         className="flex border rounded-md overflow-hidden focus-within:ring-2 focus-within:ring-emerald-500  shadow-sm focus:outline-none mt-1">
         <select required
                 id="country_id"
                 value={selectedCountry}
                 onChange={(e)=> {setSelectCountryCode(parseInt(e.target.value)); onChange && onChange()}}
                 className="text-gray-500 sm:text-sm  border-gray-300 border-e border-t-0 bg-coolGray-50 text-base leading-6 text-coolGray-500 text-normal border-b-0 border-s-0 focus:ring-0 focus:outline-none
                  border-white focus:outline-none focus:border-emerald-500">

           { countryCodes.map(country => {
             let displayCode  = CountryCode.getDisplayCodeByCode3(country.code3)
             return (<option value={country.id}  key={country.id}>{ displayCode }</option>  )
           }) }

         </select>
         <input type="text"
                required
                onChange={(e)=> {setSelectMobileNumber(parseInt(e.target.value)); onChange && onChange()}}
                id="mobile"
                name="mobile"
                className="block px-3 w-ful sm:text-sm border-0 border-white  focus:outline-none focus:ring-0 focus:border-white" />

         <input type="text" hidden={true} readOnly={true} id={"mobile_number"} value={fullMobileNumber}/>
       </div>

       {(errorBag || error) && (<span className="text-red-500 text-xs text-start leading-4 font-normal">{errorBag || error}</span> )}
     </div>
 )

}

export default AuthMobileInput
