import React, { FC, useEffect, useRef, useState } from 'react'
import AuthModalHeader from "./AuthModalHeader";
import Divider from "../Divider";
import AuthSocial from "./AuthSocial";
import { socialItemProps } from "../../../components/AuthenticationContainer";
import AuthEmailInput from "./AuthEmailInput";
import AuthPasswordInput from "./AuthPasswordInput";
import AuthApi from "./AuthApi";
import AuthButton from "./AuthButton";
import Translation from "../../helper/Translation";
import EventDispatcher from "../../helper/EventDispatcher";
import ReCAPTCHA from "react-google-recaptcha";
import AuthMobileInput from "./AuthMobileInput";
import { Country } from "@shobbak/react-services/dist/Entities";


interface LoginProps {
  socialLinks: socialItemProps[];
  onClose: () => void;
  locale: string;
  country: string;
  redirectUrl: string | null
  recaptchaEnabled: boolean;
  enabledCountries: Country[];
  defaultCountry: Country;
}

export interface ErrorBagType {
  error: string;
}

const Login: FC<LoginProps> = ({ socialLinks, country, defaultCountry, enabledCountries, locale, onClose, recaptchaEnabled = true,redirectUrl, ...props }) => {

  const [errorBag, setErrorBag] = useState<ErrorBagType>({ error: '' })
  const [formDisabled, setFormDisabled] = useState<boolean>(false)
  const [isLoginByEmail, setIsLoginByEmail] = useState<boolean>(false)
  const recaptchaRef = useRef(null)
  const loginRef = useRef(null)


  return (
    <>
      <div className={`md:p-6 p-4`}>
        <div className={`flex flex-col gap-6`}>
          <AuthModalHeader onClose={onClose} title={Translation.t('texts.sign_in')} subTitle={Translation.t('texts.login_subtitle')} headerIconName={`ri-login-box-line`} />
          <AuthSocial socialLinks={socialLinks} />
          { !!socialLinks.length && <Divider label={Translation.t('texts.or')} containerStyle={`text-coolGray-300`} /> }
        </div>
        <form ref={loginRef} onSubmit={(e) => {
          e.preventDefault()
        }}>
          <div className={`mt-4 flex flex-col gap-3`}>
            {recaptchaEnabled && (
              <ReCAPTCHA
                size="invisible"
                ref={recaptchaRef}
                sitekey={window.env?.RECAPTCHA_KEY}
              />
            )}

            <div>
              { isLoginByEmail ? 
                <AuthEmailInput error={errorBag.error} />
                : <AuthMobileInput
                    countryCodes={enabledCountries}
                    defaultSelectedCountry={defaultCountry}
                    error={errorBag.error}
                  />
              }

              <span className="mt-1.5 text-start cursor-pointer text-sm leading-5 text-normal text-coolGray-400 inline-block" onClick={() => setIsLoginByEmail(!isLoginByEmail)}>
                {Translation.t(isLoginByEmail ? 'texts.sign_in_with_mobile' : 'texts.sign_in_with_email')}
                </span>
            </div>
            
            <AuthPasswordInput withForgetPasswordButton={isLoginByEmail? 'email' : 'mobile'} />
          </div>

          <div className={`mt-4`}>
            <AuthButton onSubmit={async () => {
              let e = loginRef.current
              if (formDisabled) return;
              let recaptcha_token, params;
              params = { user: { password: e?.password.value } }
              if (isLoginByEmail) {
                params.user.email = e?.email.value
              } else {
                params.user.number = e?.mobile_number.value
              }
              if (recaptchaEnabled) {
                await recaptchaRef.current.reset()
                recaptcha_token = await recaptchaRef.current.executeAsync()
                params = { ...params, recaptcha_token: recaptcha_token }
              }
              setFormDisabled(false)
              const api = new AuthApi(locale, country)
              api.login(params)
                .then(({ url }) => {
                  // @ts-ignore
                  window.trackActivity({ event: 'login', processPayload: false, rawPayload: {}, shouldRedirect: false, callback: () => window.location.href = redirectUrl || url })
                })
                .catch(response => {
                  setErrorBag(response)
                  setFormDisabled(false)
                })
            }} label={Translation.t('texts.sign_in')} disabled={formDisabled} />
          </div>

        </form>
      </div>
      <div className="py-5 bg-coolGray-200 flex items-center justify-center gap-1 text-center">
        <p className="text-coolGray-700 text-sm leading-5 font-normal">
          {Translation.t('texts.do_not_have_account')}
        </p>
        <p className="text-emerald-500 cursor-pointer" onClick={() => EventDispatcher.dispatch('open-auth-modal', { mode: 'register' , redirectUrl: redirectUrl })}>
          {Translation.t('texts.create_new_account')}
        </p>
      </div>
    </>
  )

}





export default Login
