import React, { FC, useRef, useState } from "react";
import { UserPhoneType } from "../UserMobile";
import ReactInputVerificationCode from 'react-input-verification-code';
import BackendCall from "../../BackendCall";
import Translation from '../../../helper/Translation'
import ReCAPTCHA from "react-google-recaptcha";
import ModalComponent from "../../Modal";



interface VerifyMobileModalType {
	onClose: () => void;
	onChangeMobile: () => void;
	onAfterSubmit: (p: { id: string }) => void;
	selectedMobilePhone: UserPhoneType;
	canChangeMobile?: boolean
}


const VerifyMobileModal: FC<VerifyMobileModalType> = ({
	selectedMobilePhone,
	onChangeMobile,
	onClose,
	canChangeMobile = true,
	onAfterSubmit,
	recaptchaEnabled=true
}) => {

	const [error, setError] = useState('')
	const [number, setNumber] = useState('')
	const recaptchaRef = useRef(null)


	const verifyMobile = (code) => {
		BackendCall.i().verifyUserMobile({ mobile_id: selectedMobilePhone.id, code })
			.then(
				(res) => {
					onAfterSubmit({ id: selectedMobilePhone.id })
				},
				(errorResponse) => {
					setError(errorResponse.messageStr())
				}
			)
	}

	const resendVerificationCode = async() => {
		let payload = {}
	    if (recaptchaEnabled) {
	     let recaptcha_token = await recaptchaRef.current.executeAsync()
	      payload = { ...payload, recaptcha_token: recaptcha_token }
	    }
		BackendCall.i().resendMobileCode({ mobile_id: selectedMobilePhone.id , payload: payload})
	}


	return (
		<ModalComponent
			visible={true}
			onClose={onClose}
			body={(

			<div className="md:p-6 px-2 py-4">
				<div className="flex items-start">
					<div
						className="me-4 flex items-center justify-center h-12 w-12 rounded-full bg-emerald-50">
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M0 1C0 0.447715 0.447715 0 1 0H3.15287C3.64171 0 4.0589 0.353413 4.13927 0.835601L4.87858 5.27147C4.95075 5.70451 4.73206 6.13397 4.3394 6.3303L2.79126 7.10437C3.90756 9.87832 6.12168 12.0924 8.89563 13.2087L9.6697 11.6606C9.86603 11.2679 10.2955 11.0492 10.7285 11.1214L15.1644 11.8607C15.6466 11.9411 16 12.3583 16 12.8471V15C16 15.5523 15.5523 16 15 16H13C5.8203 16 0 10.1797 0 3V1Z"
								fill="#10B981" />
						</svg>

					</div>
					<div className="text-start flex-1 flex-col">
						<p className="text-base leading-6 font-semibold text-coolGray-900">{Translation.t('create_new_ad.enter_verification_number')}</p>
						<p className="text-sm leading-5 font-normal text-coolGray-500">{Translation.t('create_new_ad.four_digits_code')}</p>
						{canChangeMobile && (
						<p className="text-sm leading-5 font-normal text-coolGray-500">{selectedMobilePhone.number}
							<span className="text-emerald-500 ms-2 cursor-pointer"
								onClick={onChangeMobile}>{Translation.t('create_new_ad.change')}</span>

						</p>
						)}
					</div>
					<div className="self-start cursor-pointer" onClick={onClose}>
						<svg className="h-6 w-6 text-coolGray-400" xmlns="http://www.w3.org/2000/svg"
							fill="currentColor" viewBox="0 0 24 24" stroke="none">
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
								d="M12 10.586L16.95 5.63599L18.364 7.04999L13.414 12L18.364 16.95L16.95 18.364L12 13.414L7.04999 18.364L5.63599 16.95L10.586 12L5.63599 7.04999L7.04999 5.63599L12 10.586Z" />
						</svg>
					</div>
				</div>
				{ recaptchaEnabled && <ReCAPTCHA
					size="invisible"
					ref={recaptchaRef}
					sitekey={window.env?.RECAPTCHA_KEY}
					/>
				}

				<div className="mt-6" dir="ltr">
					<div className="grid gap-2 grid-cols-4 px-4 justify-center">
						<ReactInputVerificationCode length={4} value={number} onChange={(e) => {
							if (/^\d+$/.test(e)) {
								setError('')
								return setNumber(e)
							}

						}} />
					</div>
				</div>
				<div className="mt-6 flex flex-col gap-2 items-center">
					<span
						className="text-base leading-7 font-normal text-coolGray-500">{Translation.t('create_new_ad.code_will_send_in_second')}</span>
					<span
						className="text-base leading-7 font-normal text-coolGray-500 cursor-pointer"
						onClick={resendVerificationCode}>{Translation.t('create_new_ad.resend_code')}</span>
				</div>


				<input type="submit"
					disabled={number.length == 4 ? false : true}
					onClick={() => {
						verifyMobile(number)
					}} name="commit" value={Translation.t('create_new_ad.continue')}
					className={`mt-4 ${number.length == 4 ? 'cursor-pointer' : 'cursor-not-allowed'}  inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-3 bg-emerald-500 text-base font-medium text-white hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:text-sm`}
					data-disable-with="Login" />


				</div>
			)}
		/>
	)
}


export default VerifyMobileModal