import React, { useEffect, useState } from 'react'
import Translation from "../src/helper/Translation";
import BackendCall from "../src/Components/BackendCall";
import { Posting } from "@shobbak/react-services/dist/Entities";
import Loader from "../src/Components/icon/Loader";
import { Services } from '@shobbak/react-services';
import EditDeliveryOptionsModal from "./EditDeliveryOptionsModal";
const { Helpers: { imageUrl } } = Services;


const DeliveryOptions = ({token , locale ,authUser,postingLocationUrl,googleMapsApiKey, postingId }) => {


  const[posting , setPosting] =useState <Posting | any >({})
  const [isLoading , setIsLoading] = useState<Boolean>(true)
  const [editDeliveryOptionsModalEnable , setEditDeliveryOptionsModalEnable] = useState<Boolean>(false)

  useEffect(()=> {
    BackendCall.i({token , locale})
               .getPosting(postingId)
               .then(postingResponse =>  {
                 setPosting(postingResponse)
                 setIsLoading(false)
               } )
  } , [])




  return (
    <div className="flex flex-col  md:border md:rounded-md bg-white p-4">
      <div className="flex justify-between items-center w-full">
        <h3 className="text-lg leading-6 font-semibold text-coolGray-700">{Translation.t('texts.delivery_options')}</h3>
        <div className="flex items-center justify-center gap-2 text-emerald-500 cursor-pointer" onClick={()=> setEditDeliveryOptionsModalEnable(true)}>
          <span className="text-base leading-6 font-normal cursor-pointer">{Translation.t('texts.edit')}</span>
          <i className="ri-edit-fill text-sm"/>
        </div>
      </div>


      {editDeliveryOptionsModalEnable && (  <EditDeliveryOptionsModal googleMapsApiKey={googleMapsApiKey}
                                                                      authUser={authUser}
                                                                      locale={locale}
                                                                      token={token}
                                                                      posting={posting}
                                                                      onClose={()=> setEditDeliveryOptionsModalEnable(false) } />)}


      { isLoading ? <Loader /> : (
      <>
          <div className="flex w-full gap-2 bg-white mt-6 border border-coolGray-100 rounded-md p-2">
            {posting.deliveryOptions.map((deliveryOption)=> {
              return (
                <div className="flex justify-center items-center bg-coolGray-50 py-4 rounded-md w-full" key={deliveryOption.id}>
                  <img src={deliveryOption.icon} className={"w-6 h-4 object-contain"} />
                </div>
              )
            })}


          </div>

          <div className="flex gap-2 bg-white mt-4 border border-coolGray-100 rounded-md p-2">
            <img src={postingLocationUrl} className="w-14 h-14 rounded-md"/>
              <div className="flex flex-col">
                <p className="text-coolGray-700">{posting?.addressLine}</p>
              </div>
          </div>

          <div className="pt-1 flex gap-2 bg-blue-50 text-sm text-blue-500 mt-1 rounded-md px-2">
            <i className="ri-shield-check-fill" />
            <span>{Translation.t('texts.location_will_be_hidden')}</span>
          </div>
      </>
      )}


    </div>
  );

}


export default DeliveryOptions ;