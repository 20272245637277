import React, { useEffect } from 'react'

const NotificationIcon = (props) => {
  return (
    <svg className={props.className} viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M13.5997 12.8L13.333 12.4447V6.66671C13.333 3.72119 10.9452 1.33337 7.99968 1.33337C5.05416 1.33337 2.66634 3.72119 2.66634 6.66671V12.4447L2.39968 12.8C2.32392 12.901 2.31174 13.0362 2.3682 13.1491C2.42466 13.262 2.54009 13.3334 2.66634 13.3334H13.333C13.4593 13.3334 13.5747 13.262 13.6312 13.1491C13.6876 13.0362 13.6754 12.901 13.5997 12.8ZM9.66634 14H6.33301C6.33301 14.9205 7.0792 15.6667 7.99968 15.6667C8.92015 15.6667 9.66634 14.9205 9.66634 14Z"/>
    </svg>

  );
}


export default NotificationIcon